import { Container } from "@mui/material"
import React, { useRef } from "react";



export const JustTermsAndAggrement = () => {

    return <Container>
        <br />
        <div className="text-center text-3xl">הסכמים ותנאי שימוש</div>
        <div>    
        <br />
    התנאים להלן מפרטים את תנאי הרישיון לשימוש בתכנת Meetly. התנאים מהווים הסכם משפטי מחייב בינך לבין חברת Meetly לעסקים בע”מ. אנא קרא היטב את תנאי השימוש ואת מדיניות הפרטיות של Meetly המפורסמת אף היא באתר Meetly וודא כי הנך מבין איזה שימוש עושה Meetly במידע הפרטי שלך קודם לרישום ולהתחלת השימוש באתר ובמערכת. בעת כניסתך הראשונה למערכת תחויב ללחוץ על “אני מאשר” ובכך הנך מסכים ומתחייב למלא אחר כל תנאי הרישיון. בנוסף, שימוש במערכת (כהגדרתה להלן) יהווה הסכמה מצדך להיות מחויב על פי תנאיו כמו גם על פי תנאי מדיניות הפרטיות של Meetly. במידה ואינך מסכים לכל התנאים בהסכמי רישוי אלו, אל תשתמש במערכת.
<br />
<br />
1. הגדרות
		“התכנה” או “המערכת“- תוכנת כשרות (SaaS) לניהול עסקים קטנים הידועה בשם “Meetly“;
		“Meetly” – חברת מיטלי עוסק מורשה
		“דמי שימוש” – תשלום עבור שימוש בתוכנה לתקופת המינוי, וכן תוספות תשלומים נוספים העשויים לחול בגין שימוש עודף, תכונות נוספות חדשות, מאפיינים ותוספים מסויימים וכדומה;
		“תקופת ניסיון” – תקופה בת שבעה ימים לשימוש בגרסה המלאה של התכנה ללא תשלום דמי שימוש.
		“תקופת המנוי” או “תכנית השימוש” – תקופה שאינה מוגבלת בזמן ותתחדש מידי חודש או שנה בכפוף לתשלום דמי השימוש באופן חודשי ולפי תכניות שימוש אופציונליות כמפורט להלן.
        <br />
<br />
2. תנאי השימוש בתכנה

		התכנה לרבות כל זכויות היוצרים והקניין הרוחני בה היא רכושה וקניינה הבלעדי של Meetly. התכנה מוגנת בחוקי זכויות יוצרים ובאמנות בינלאומיות לזכויות יוצרים, וכן באמנות ובחוקים אחרים להגנה על קניין רוחני. ניתן לך רשיון להשתמש בתכנה, אך התוכנה אינה נמכרת לך.
		רישיון זה מעניק לך זכות שימוש אישית, בלתי ניתנת להעברה וניתנת לביטול לשימוש בתכנה במשך תקופת הניסיון ותקופת המנוי, בכפוף לקיום תנאי הרישיון. אתה מתחייב שלא להעביר את זכויותיך או את התחייבויותיך במסגרת הסכם זה ללא הסכמה מראש ובכתב מאת Meetly.
		השימוש בתכנה הוא על פי תנאי רישיון זה. אינך רשאי להכניס בהסכם זה שינוי, תיקון, השמטה או מחיקה כלשהם, אלא אם ניתנה לכך הסכמה בכתב מאת Meetly.
		הרישיון ניתן עבור שימוש בתכנה למשתמש אחד ללא תלות במיקום.
		התכנה מותקנת ונשמרת על גבי שרתים המופעלים על ידי ספק חיצוני לרבות באמצעות ענן מחשוב. השירותים שמספקת Meetly עלולים מעצם טבעם להיות נתונים להפסקות ו/או הפרעות ו/או תקלות טכניות שונות, לרבות תקלות בתכנה, בחומרה, בקווי תקשורת, או בתשתית הפיזית של Meetly, לרבות הפרעות/הפסקות זמניות או קבועות וכן כפופים לעומסי התעבורה ברשת האינטרנט העולמית והמקומית. Meetly אינה אחראית ולא תהיה חייבת בפיצוי בגין כל נזק, אובדן מידע וכדומה שייגרם כתוצאה מתקלה טכנית הנובעת מתפעול השרתים.
		Meetly אינה אחראית לאבטחת מידע, להגנה או לגיבוי הנתונים שנשמרו במחשבי ו/או שרתי Meetly ו/או מי מטעמה על ידך או עבורך, לרבות עקב חדירה של גורם בלתי מורשה לתחומו של הלקוח במחשבי Meetly, ולרבות השחתה, חשיפה, או מחיקה של נתונים.
		דמי השימוש בתכנה מפורטים באתר Meetly בכתובת האינטרנט meetly.co.il (להלן: “אתר Meetly“). Meetly מאפשרת בחירה בתכנית שימוש מבין מספר תכניות שימוש אופציונליות כמפורט באתר Meetly. דמי השימוש בתכנה ישולמו על ידך באופן חודשי בתאריך שיקבע על ידך, בהתאם לתכנית השימוש שבחרת בה בעת הרישום לשירות. דמי השימוש המפורסמים באתר Meetly אינם כוללים מע”מ. דמי השימוש ותכניות השימוש האופציונליות עשויים להתעדכן מעת לעת על פי שיקול דעתה המוחלט של Meetly. עדכון בדבר שינוי בגובה דמי השימוש יישלח אליך בדוא”ל לפי הפרטים שתספק בעת הרישום לשירות. דמי השימוש אינם משתנים בהתאם למידת השימוש בתכנה. דמי השימוש ישולמו על ידך גם אם לא יעשה שימוש בתכנה תקופה כל שהיא. במידה ולא תעשה שימוש בתכנה, לא תהיה זכאי להחזר כספי בגין תקופה זו.
		התכנה נתונה לשינויים ועדכונים באופן תמידי. שירותים מסוימים הקיימים בתכנה או שיתווספו לתכנה עשוי שיחולו עליהם תנאים והסכמים נוספים, לרבות דמי שימוש נוספים.
		Meetly רשאית מעת לעת לשנות, להוסיף, או לגרוע מהתכנה רכיבים ללא הודעה מוקדמת, ובין היתר תכונות, ממשקים ושדות לשימוש ולשמירת נתונים – הכל על פי שיקול דעתה הבלעדי של Meetly.
		Meetly רשאית לשנות או לקבוע בכל עת הגבלות או נהלים נוספים עבור תכונות, מוצרים או שירותים המסופקים או שיסופקו על ידה לרבות באמצעות התוכנה, והכול על פי שיקול דעתה הבלעדי של Meetly.
		אין לעשות שימוש בתוכנה בכל דרך העלולה לפגוע בזכויות צדדים שלישיים ו/או בהוראות כל דין. הינך מתחייב כי לא תבצע בין בעצמך ובין באמצעות אחרים, במישרין ו/או בעקיפין כל תוספת, גריעה, שינוי, תרגום, התאמה בדרך כלשהי בתוכנה.
		Meetly לא תהיה אחראית לשינויים שנערכו בתכנה שלא על ידי Meetly, ו/או בתפקוד התכנה כפי שנמסרה על ידי Meetly.
		הינך מסכים ומאשר לMeetly כי השימוש בתוכנה, במידע, בשירותים ובתכנים הכלולים בה ישמרו במאגרי Meetly וישמשו את Meetly לכל שימוש הנחוץ לתפעול התוכנה. Meetly מתחייבת לשמור על סודיות המידע, בכפוף להוראות הדין ותנאי השימוש. Meetly תימנע מגילוי מידע או פרטים אלא אם הדבר מחויב על פי דין או מותר לפי תנאי השימוש או נדרש במסגרת הליכים משפטיים לפי צו מחייב של בית משפט או רשות מוסמכת, או במסגרת הליך משפטי, ו/או במקרה של אי קיום הוראות הדין או תנאי השימוש.
		אנו מודעים לכך שהמידע שתעביר באמצעות המערכת, לרבות בקשר ללקוחותיך, הינו מידע סודי העשוי לכלול מידע אישי רגיש ועל כן הנך מתחייב לפעול, בקשר עם המידע הסודי כאמור, על פי הוראות חוק הגנת הפרטיות, התשמ"א – 1981 (להלן: "חוק הגנת הפרטיות") והתקנות אשר הותקנו או יותקנו מכוחו (לרבות, מבלי לגרוע, תקנות הגנת הפרטיות (אבטחת מידע), התשע"ז – 2017) (להלן: "תקנות אבטחת מידע"), כפי שיהיו בתוקף מעת לעת, וכן לפעול ביחס למידע הסודי בהתאם להנחיות והוראות רשם מאגרי המידע והרשות להגנת הפרטיות כפי שיהיו מעת לעת, לרבות רישום מאגר מידע כדין.
		מבלי לגרוע מהאמור לעיל ומדרישות כל דין, הנך פוטר את Meetly מכל תביעה ו/או דרישה ו/או טענה כנגד Meetly ו/או המערכת בגין איסוף ועיבוד המידע האישי אשר באחריותו של בית העסק. Meetly תנקוט את מלוא האמצעים הנדרשים על מנת לשמור על אמצעי הגנה מנהליים, פיזיים וטכניים לאבטחת המידע הסודי ולהגנה על הסודיות, הפרטיות הזמינות והשלמות של המידע הסודי, בהתאם להוראות הדין.
		יובהר בזאת כי הנך מתחייב שבכל מקרה של ביטול הסכם ההתקשרות בינך לבין Meetly, כל המידע שהעברת באמצעות המערכת ו/או עובד באמצעות המערכת ו/או נשמר במערכת, לרבות מידע אישי ורגיש של לקוחותיך ילקח על ידך ויימחק משרתי המערכת, בכפוף להסכמת Meetly, לכל המאוחר עד למועד הפסקת ההתקשרות כאמור.
        <br />
<br />
3. תמיכה, שירות וגיבוי
		Meetly מתחייבת להעניק במשך תקופת המנוי שירותי תמיכה בשימוש והפעלת התכנה בימים א-ה בשעות בין השעות 09:00-16:00 למעט ערבי חג וימי חג ומועד. לשם קבלת התמיכה הינך מתבקש לפנות באמצעות אדם בעל רמת ידע סבירה של סביבת windows או mac.
		שירותי התמיכה הניתנים כוללים טיפול בבעיות טכניות הקשורות למערכת. שירותי התמיכה הטכנית יינתנו אך ורק כאשר התקלה חלה בשרות הניתן על ידי Meetly ו/או באחריותה.
		לשם קבלת התמיכה, אתה מתחייב לתת מענה לבקשות של נציגי התמיכה הטכנית ולאפשר להם, במידת הצורך, גישה ושימוש במידע השמור במחשביך. Meetly תהיה רשאית להשתמש בכל מידע טכני שתמסור לה כחלק משירותי התמיכה והעדכון לצורך מטרותיה העסקיות, לרבות תמיכה במוצר ופיתוח מוצר. Meetly לא תשתמש במידע טכני כאמור באופן שיזהה אותך באופן אישי.
		לשם קבלת התמיכה, אתה מתחייב לתת מענה לבקשות של נציגי התמיכה הטכנית ולאפשר להם, במידת הצורך, גישה ושימוש במידע השמור במחשביך. Meetly תהיה רשאית להשתמש בכל מידע טכני שתמסור לה כחלק משירותי התמיכה והעדכון לצורך מטרותיה העסקיות, לרבות תמיכה במוצר ופיתוח מוצר. Meetly לא תשתמש במידע טכני כאמור באופן שיזהה אותך באופן אישי.

		לשם קבלת התמיכה, אתה מתחייב לתת מענה לבקשות של נציגי התמיכה הטכנית ולאפשר להם, במידת הצורך, גישה ושימוש במידע השמור במחשביך. Meetly תהיה רשאית להשתמש בכל מידע טכני שתמסור לה כחלק משירותי התמיכה והעדכון לצורך מטרותיה העסקיות, לרבות תמיכה במוצר ופיתוח מוצר. Meetly לא תשתמש במידע טכני כאמור באופן שיזהה אותך באופן אישי.
		לשם מתן התמיכה, Meetly תהיה רשאית לגשת, במישירין או בעקיפין לכל המידע השמור במאגר המידע אודות העסק, לרבות מאגר הלקוחות, נתונים חשבונאים ואחרים.
		Meetly אינה מתחייבת לתקן שגיאות ו/או תקלות בתכנה והן יתוקנו מידי פעם בגרסאות חדשות ומעודכנות של התכנה אשר ייצאו לאור על פי לוחות הזמנים אצל Meetly. Meetly תסייע בפתרון תקלות במידת האפשר בכפוף למשלוח ההודעה על שגיאה ו/או תקלה בתכנה ו/או ליקוי.
		Meetly רשאית לבצע מעת לעת עבודות תחזוקה וכן שינויים, שיפורים ותיקונים בתכנה, ובמידע, בשירותים ובתכנים הכלולים בו, וזאת לפי שיקול דעתה הבלעדי וללא צורך בהודעה מראש.
		Meetly אינה מחוייבת להעניק תמיכה טכנית כאשר מקור התקלה נובע מוירוס מחשב ו/או פגיעה פיזית בחלקי המחשב ו/או כל שימוש בתוכנה הנוגד את הוראות Meetly ו/או תקלה בשרתים או בענן המחשוב בו מאוכסנת התכנה.
		Meetly מבצעת באופן שוטף גיבויים של התכנה ובסיס הנתונים אך אין להסתמך על גיבוי זה בלבד. Meetly אינה מתחייבת לבצע שמירה או גיבוי של המידע, המסמכים או הנתונים בהם עשית שימוש. הנך מתחייב לשמור באופן רציף וקבוע עותק מעודכן של כל הקבצים והנתונים שאתה מעלה למערכת. הנך מאשר כי לא תהיה לך כל טענה ו/או תביעה ו/או דרישה כלפי Meetly בקשר עם אובדן של קבצים ונתונים כאמור, מכל סיבה שהיא. במקרה של אובדן נתונים, Meetly תעשה כמיטב יכולתה כדי לסייע בשחזור החומר באמצעות הגיבוי שעומד לרשותה באותה עת, אך לא תהיה אחראית לכל אובדן נתונים שהשחזור עבורם לא צלח.
        <br />
<br />
4. התחיבות הלקוח
		הנך מצהיר כי יש לך הסמכות והזכות להתקשר בהסכם זה. מבלי לגרוע מכלליות האמור, הנך מצהיר כי אין בדין ו/או בחוזה ו/או במסמכי ההתאגדות מניעה כלשהי מהתקשרותך בהסכם זה. במקרה של לקוח שהוא יחיד, הנך מצהיר כי אתה בגיר וכשיר עפ”י דין.
		הנך מתחייב להשתמש בשירותי המערכת באופן חוקי בלבד ובהתאם להוראות כל דין. מבלי לגרוע מכלליות האמור, ובכפוף להוראות כל דין, הנך מצהיר ומתחייב כי לא תעשה שימוש בשירותי Meetly בניגוד לדין ו/או לכל הסכם.
		אתה מצהיר בזאת כי ידוע לך שהשימוש בתכנה בתום תקופת הניסיון כפוף לתשלום דמי השימוש המפורטים באתר Meetly. אתה מתחייב לשלם את דמי השימוש כמפורט להלן.
		אתה מתחייב כי לא תבצע בין בעצמך ובין באמצעות אחרים במישרין ו/או בעקיפין כל תוספת, גריעה, שינוי, תרגום, התאמה בדרך כלשהי בתוכנה ו/או בתיעוד שלה. בכל מקרה Meetly לא תהיה אחראית לשינויים בתוכנה ולתפקוד התוכנה כפי שנמסרה על ידי Meetly ואשר שונתה בעקבות שינויים שביצעת.
		הינך מתחייב שלא להעתיק (בכל מדיה, אלקטרונית, דיגיטאלית, או מכאנית) את התכנה ו/או חלקים ממנה, לרבות הגרפיקה, עיצוב המסכים, הנתונים והפקודות ושלא לשכפל, ליצור או לפתח חיקויים המבוססים על התוכנה, לא לבצע או לאפשר לאחרים לבצע הנדסה חוזרת של התוכנה, לא לעשות כל שימוש בתוכנה במטרה ליצור מוצר או שירותים המתחרים בתוכנה, מתממשקים עימה, או משלימים לה בכל דרך שהיא, ולא לפגוע בצורה כלשהי בזכויותיה של Meetly בתכנה או בעסקי Meetly .
		הינך מתחייב שלא לאפשר, במישרין או בעקיפין, לצדדים שלישיים כלשהם גישה כלשהי לתוכנה ו/או שימוש בשירותים באמצעות התכנה.
		הינך מתחייב לשמור בסודיות את שם המשתמש והסיסמא הנדרשים לשם חיבור לתוכנה ולא להעבירם או לגלותם למי שאינו עובד או מורשה מטעמך.
		גיבוי המידע והמסמכים המקושרים עם התוכנה ובדיקת תקינות הגיבוי הינם באחריותך הבלעדית. Meetly אינה אחראית לכל נזק או אובדן שייגרמו כתוצאה מכשלונך לגבות או לשמור מידע, מסמכים, וכל תוכן אחר אשר לא סופק על ידי Meetly ולא יהיו לך כל טענה או זכות לתביעה, לפיצוי או לשיפוי כנגד Meetly בקשר עם אובדן נתונים או מידע מכל סיבה שהיא.
		מובהר בזאת כי על פי הוראות החוק, מחשב המפיק תיעוד פנים מחוייב בחיבור למכשיר אל-פסק. התקנת וחיבור המכשיר הוא באחריותך הבלעדית ולMeetly אין כל אחריות לתקינות המכשיר, תקינות החיבור, ולכל נזק או אבדן שיגרם כתוצאה מאי התקנתו או מכשל בפעולתו.
		אתה מצהיר בזאת כי החומרה ומערכת ההפעלה שברשותך תואמים להגדרות המינימום של Meetly המפורטות באתר Meetly ואשר עלולות להשתנות מעת לעת.
        <br />
<br />
5. חשבונות ותנאי תשלום
		תכניות השימוש האפשריות במערכת מפורטות באתר Meetly והן כפופות לשינויים שיפורסמו מפעם לפעם על ידי Meetly באתר Meetly. תכניות השימוש כוללות תשלום חודשי קבוע וכן תשלום עבור שימוש חורג. מובהר כי תחויב בתשלום הקבוע בין אם היה מנוי לחודש שלם או לחלק ממנו וכן בין אם השתמשת בפועל בשירותים ובין אם לאו. ביטול או שינוי התוכנית אשר הותאמה עבורך, מותנה בהסכמת Meetly ולשיקול דעתה הבלעדי.
		התשלום עבור השירות יבוצע באמצעות חיוב מראש של אמצעי התשלום שאת פרטיו מסרת במעמד הזמנת תכנית השימוש השירות .התשלום עבור תקופת השירות הראשונית יבוצע עם הזמנת השירות תכנית השימוש. תשלום עבור חידוש תקופת השירות תכנית השימוש יבוצע מראש, באמצעות אותו אמצעי התשלום, אלא אם כן תודיע לMeetly על שינוי פרטי אמצעי התשלום שאתה מבקש לחייב.
		תשלום עבור שימוש החורג בהיקף המפורט בהזמנת השירות בתכנית השימוש יתבצע בסוף כל חודש קלנדרי, בהתאם לתעריפי Meetly ועל פי היקף השימוש החורג לפי רישומי Meetly.
		בסמוך לאחר חיוב אמצעי התשלום, תשלח אליך Meetly חשבון, שבו יפורט סכום החיוב, ובמקרה של תשלום נוסף עבור שימוש החורג מהיקף ההזמנה, יפורט גם היקף החריגה. החשבון ישלח לכתובת הדואר האלקטרוני שתספק לMeetly. הנך מתחייב לעדכן את Meetly לגבי כל שינוי שיחול בכתובת דואר זו. לא תינתן אפשרות לקבלת חשבונית עבור השירות באמצעי שאינו אלקטרוני.
		מבלי לגרוע מכל סעד אחר שMeetly זכאית לו, ומבלי לגרוע מסמכותה של Meetly להפסיק את מתן השירות, הרי כל תשלום אשר לא ישולם במועדו, ישא ריבית פיגורים והוצאות גביה החל במועד שבו היה אמור להשתלם ועד למועד התשלום בפועל. לעניין זה, ריבית פיגורים הינה ריבית הפיגורים המתפרסמת על ידי החשב הכללי של משרד האוצר(ריבית חח”ד).
		Meetly תהא רשאית לחסום גישה לשירות ולתכנה במקרה של פיגור בביצוע התשלום לתקופה של חודש ימים. חסימת משתמש כאמור תתבצע ב-1 בחודש העוקב ובחלוף 24 שעות ממתן התראה באמצעות סמס או דוא”ל בדבר הפיגור בתשלום.

		התוכנה, מאגרי נתונים, סימני ושמות מסחר, יישומים, מידע אחר, הסודות המסחריים והמקצועיים הנוגעים לתכנה ולאתר Meetly הינם קניינה הרוחני של Meetly ושייכים באופן בלעדי לMeetly. אין להשתמש, לשנות, להעתיק (בכל מדיה או אמצעי, אופטי, מכאני, אלקטרוני או אחר), לחקות, לשכפל, להפיץ, להציג בפומבי, לבצע בהם ו/או בחלקים מהם הנדסה חוזרת או לאפשר לצד שלישי כלשהו לעשות פעולות אלה בלא קבלת אישור בכתב ומראש מMeetly.
		הרישיון המוענק לך בזאת הינו רישיון לשימוש בתכנה בלבד ואין בו כל רישיון, הרשאה או מתן זכות אחרת בקניינה הרוחנית של Meetly.
		ביצוע כל אחת מהפעולות שנאסרו עליך בסעיפים 4.4 עד 4.6 כולל ובסעיף 6.1 לרשיון זה תהווה הפרה של זכויות הקניין הרוחני של Meetly. הדין מקנה לMeetly, בין היתר, זכות לפיצויים ללא הוכחת נזק של עד 100,000 ₪ בגין כל הפרה של זכויותיה.
		עשיית עותק מפר של התכנה לשם מסחר בו, מכירתו, השכרתו או הפצתו בהיקף מסחרי או החזקתו לשם מסחר בו, מהווה עבירה פלילית על פי החוק.

        <br />
<br />
7. אחריות
		אחריות Meetly מוגבלת לאמור בסעיף 3 לעיל בלבד. מעבר לאמור לעיל לא תישא Meetly באחריות אחרת במפורש או מכללא, לרבות תשלום בגין דמי נזק פיצויים ישירים, עקיפים, תוצאתיים שיגרמו לך ו/או לצד שלישי כלשהו לרבות לקוחותיך, או בתשלום בגין הפסדי אובדן רווחים, בין אם נבעו מרישיון זה או מדרך ביצועו, ו/או כתוצאה מפעולה או אי פעולה, שימוש או אי שימוש בתכנה או בנתונים שהוזנו אליה על ידך או הופקו ממנה.
		Meetly אינה אחראית לכל נזק שיגרם כתוצאה מכשלים טכניים בתכנה, במערכות ההפעלה, במחשבים אשר ברשותך, בשרתים, ו/או כתוצאה מפעולות ו/או מטעות אנוש בתום לב, ברשלנות או במזיד אשר אין לMeetly שליטה בהן, ו/או עיבוד מידע אישי, לרבות מידע אישי רגיש, שהוכנס/הועבר על ידך ו/או מי מלקוחותיך למערכת, מבלי /ניתנה הסכמת הלקוח לכך. למען הסר ספק, מובהר בזאת, כי הנך מתחייב בזאת כי כל מסירת מידע על ידי מי מלקוחותיך ו/או על ידך באמצעות המערכת נעשתה בכפוף לרצונו ובהסכמתו המפורשת של הלקוח. הפרה של סעיף זה יזכה את החברה בפיצוי מוסכם, ללא הוכחת נזק, של ₪100,000 בגין כל הפרה של זכויותיה; וזאת מבלי לגרוע מכל זכות לה זכאית החברה על פי דין.
		Meetly אינה אחראית לגבות או לתעד פעולות עדכון, תמיכה ופעולות אחרות אשר יבוצעו על ידי Meetly או על ידך או על ידי צד שלישי כלשהו, הנוגע לתוכנה ו/או למידע השמור בה.
		Meetly אינה אחראית לעדכון משתנים חיצוניים שאינם חלק מהמערכת כגון עדכון גובה אחוזי מע”מ, עדכון מיסים וכדומה. במידה ופרמטרים אלו משתנים, עליך לעדכנם.
		Meetly אינה אחראית להעסקה לא חוקית של עובדים ו/או עדכון פרטים כוזב במערכת המהווים גם עבירה פלילית על פי החוק.
		התוכנה מועמדת לרשותך על פי תנאי רשיון זה כמות שהיא As Is)) Meetlyאינה מתחייבת לעשות כל שינוי בתוכנה על מנת להתאימה לצרכיך. חרף האמור, Meetly תשמח לקבל הצעות ייעול והיא רשאית, אך לא מחוייבת, ליישמן בגירסאות עתידיות של התוכנה, מבלי שתהיה לך כל זכות או טענה לזכויות בעניין זה.
		בכפוף לתנאי רישיון זה, ניתן לך בזאת רישיון לשימוש בתכנה ללא התחייבות או אחריות כלשהי, מפורשת או מכללא כי הפונקציות הכלולות בתוכנה תענינה לצרכיך או שפעולות התוכנה תימשך ללא הפסקה או תקלה או שהתוכנה תהא ללא שגיאות.
		התכנה היא תכנה מאושרת על ידי מס הכנסה ורשומה באתר רשות המסים, אולם Meetly אינה אחראית לדיוק של התכנה ו/או לדיוק תוצאות המופקות ממנה ו/או לדיווחים כוזבים. Meetly אינה אחראית ולא תישא בכל חבות לגבי חומרה, תוכנה או פריטים אחרים, או לכל שירות הניתן על ידי כל אדם מלבד Meetly. Meetly אינה אחראית לתוצאות השימוש בתוכנה לרבות עקב היעדר יכולת להשתמש בתוכנה, אובדן מידע, שירותים או תכנים, לרבות הפסד הכנסה או הפסד רווח או עיכוב בפעילות עסקית או אחרת, או פגיעה במוניטין, עקב שימוש או היעדר אפשרות לעשות שימוש בתוכנה או בכל חלק ממנה והינך מסכים בזאת כי לא תהא לך כל טענה או תביעה בנדון.
		Meetly אינה אחראית למעשים ו/או מחדלים אשר בוצעו על ידך או על ידי לקוחות אחרים ו/או כל גורם אחר שאין לMeetly שליטה עליו, או לתוצאות של מעשים או מחדלים כאמור, ובמיוחד לפגיעה או להפרה של הסכמים ו/או זכויות יוצרים, או קניין רוחני אחר על ידך או לקוחות אחרים בתוכנה.
		אישורך לתנאי רישיון זה ו/או שימוש בתוכנה מהווה הסכמה מצידך להגבלות האחריות המפורטות לעיל, מהווה סילוק, וויתור ופטור באופן סופי, מוחלט ובלתי חוזר מכל אחריות שהוגבלה כאמור, של כל טענה ו/או תביעה ו/או דרישה כנגד Meetly, דירקטורים, בעלי תפקידים, מנהלים, ישויות קשורות, עובדים, ספקים, קבלנים, יועצים, בעלי מניות ומי מטעמה של Meetly בעניינים אלה.
		הנך מתחייב בזאת לפצות את Meetly ואת בעליה, מנהליה, יועציה, עובדיה או מי מטעמה בגין כל נזק, הפסד, אבדן רווח, תשלום או הוצאה שייגרמו להם עקב הפרת תנאי שימוש מערכת אלה, לרבות הפרת הוראות כל דין, בין היתר אך לא רק הפרת הוראות חוק הגנת הפרטיות ותקנות הגנת הפרטיות, או עקב כל נזק שייגרם או שעלול להיגרם למערכת בגין שימוש במערכת שלא למטרות המוזכרות בתנאי שימוש אלה.
		מובהר כי הנך מתחייב לרשום מאגר מידע בהתאם להוראות חוק הגנת הפרטיות ותקנות הגנת הפרטיות, ובכפוף לאמור בסעיף 2.14 לעיל.
        <br />
<br />
8. פרטיות
		איסוף מידע פרטי על ידי אתר Meetly והמערכת מוסדרים במדיניות הפרטיות המפורסמת Meetly . Meetly מחויבת להגן על המידע האישי שלך (כהגדרתו במדיניות הפרטיות של Meetly) באופן העולה בקנה אחד עם חוקי הגנת המידע והפרטיות התקפים.
		הנך מתחייב לעשות שימוש במערכת בהתאם להוראות המפורטות בתנאי שימוש אלו ובמדיניות הפרטיות של Meetly המפורסמת באתר וכן בהתאם לתנאי השימוש ומדיניות הפרטיות של פלטפורמת הרשת החברתית באמצעותה יכול להתווסף לקוח למערכת (כמפורט במדיניות הפרטיות).
        <br />
<br />
9. עדכון אוטומטי
		התוכנה מכילה רכיב עדכון אוטומטי המופעל כברירת המחדל אשר Meetly רשאית לשנותו או לבטלו בכל זמן. Meetly תשלח אליך מפעם לפעם הודעות על עדכונים. אישורך לתנאי הסכם רישיון זה מהווה הסכמה לקבלת העדכונים מעת לעת בהתאם עם הוראות סעיף 30א. לחוק התקשורת (בזק ושידורים), תשמ”ב-1982. אין באפשרותך לבטל את רכיב העדכון האוטומטי בהגדרות התוכנה והנך מתחייב שלא לעשות כן.
		יישום העדכונים בעת השימוש בתכנה הינו באחריותך הבלעדית. במידה ואתה זקוק להדרכה ביישום העדכון, אתה מתבקש לפנות אל שירותי התמיכה הטכנית בשעות הפעילות המפורטות.

        <br />
<br />
10. תקופת ותנאי הרישיון

		תקופת הרשיון תהיה תקופת המנוי על פי הזמנת השירות שביצעת באתר Meetly, והתקופות הנוספות שיתווספו אליה בהתאם להוראות שלהלן. דמי המנוי הם תשלום חודשי מתחדש לפי תכנית השימוש בה בחרת עם הרישום לשירות.
		למרות האמור לעיל תהא Meetly רשאית להפסיק, להתלות או להגביל בכל עת את הרישיון ו/או פעילות התוכנה, אפשרויות השימוש בה, את הגישה או השימוש במידע, בשירותים ובתכנים הכלולים בה, כולם או חלקם, ו/או לבטל רשיון זה, בכל אחד מהמקרים המפורטים להלן:
		הלקוח לא שילם לMeetly  את דמי המנוי ו/או כל תשלום אחר שהוא חב בו בעד השירותים שהוא מקבל מMeetly ;
		הלקוח לא מסר פרטי זיהוי נכונים;
		הלקוח הפר תנאי מהותי בהסכם;
		Meetly סבורה, לפי שיקול דעתה, כי נעשה שימוש לרעה בשירות הניתן ללקוח, באופן שעלול לגרום נזק לציבור ו/או לMeetly  ו/או ללקוחותיה;
		הלקוח הפסיק את פעילותו העסקית, או שנפתחו נגדו הליכי כינוס נכסים או מימוש שעבוד או שהוטל עיקול על זכויותיו בMeetly ;
		קיימת מגבלה חוקית על המשך מתן השירות;
		במקרה של תקיפה של הלקוח על ידי צד שלישי, אשר Meetly סבורה לפי שיקול דעתה כי עלולה לגרום נזק למחשבי Meetly או ללקוחותיה. במקרה כזה הפסקת השירות תהיה לפרק זמן מינימלי עד להסרת החשש כאמור;
		הינך מצהיר כי אתה מבין את גודל הנזק העלול להיגרם לMeetly , בגין הפרת תנאי הרשיון המפורטים ברשיון זה, וכי תפצה את Meetly בגין הפרת תנאי הרישיון דלעיל, כולם או חלקם בין על ידך או בין על ידי מי מעובדיך ו/או כל צד שלישי המצוי בשליטתך או שפעל לבקשתך או תחת הנחיותיך. בין היתר, הינך מתחייב לשלם לMeetly  בגין כל הפרה כאמור פיצויים מוסכמים בסך 100,000 ₪, מבלי לפגוע בכל זכות, טענה תביעה או פיצוי אחר המגיע לMeetly  על פי כל דין.
		בנוסף, אתה מתחייב בזאת לשפות את Meetly במלואו על כל נזק ו/או הוצאה ו/או הפסד שיגרם לה, לרבות נזקי מוניטין, בגין או בקשר עם שימוש לא מורשה בתכנה, לרבות הפצתה לצדדים שלישיים או בגין שימוש שלא בהתאם להוראות התכנה או לתנאי רישיון זה לרבות הוצאות משפטיות ושכר טרחת עורך דין.
		במקרה של שינוי נסיבות שאינן בשליטת Meetly, שיחייב שינוי מהותי בתנאי הרשיון, רשאית Meetly לשנות את הרשיון באופן שתחולת השינוי תהיה מידית. במקרה כזה, הנך רשאי להודיע על ביטול המנוי שלך באופן מיידי תוך מתן הודעה מוקדמת, למעט במקרה בו נדרש השינוי בעקבות שינוי הדין הקיים.
		היה וייקבע על ידי בית משפט בפסק-דין חלוט, סופי ובלתי ניתן לערעור שהשימוש בתכנה על ידך מהווה הפרה של זכויות קניין רוחני השייכות לצד שלישי, תעמוד לMeetly  הבחירה, מבלי שאתה תחויב בעלויות כלשהן, בין (א) להשיג עבורך רשיון שיאפשר לך להמשיך להשתמש בתכנה, (ב) להחליף את התכנה בתוכנה שוות-ערך במהותה, או (ג) לסיים רישיון זה ללא חבות נוספת של כל צד כלפי משנהו. הינך מסכים בזאת כי לא תהא לך כל טענה ו/או תביעה נוספות במקרה כזה.
        <br />
<br />

11. הפסקת השירות ו/או ביטול הרשיון על-ידי Meetly
		
		Meetly רשאית להפסיק באופן מיידי את השירותים הניתנים לך, כולם או מקצתם, באופן זמני ולפרק זמן מינימלי במידת האפשר, תוך מתן הודעה ככל שהדבר ניתן בנסיבות העניין, אם הדבר דרוש לצורך תפעולה, תחזוקתה או הרחבתה של חוות השרתים של Meetly, או מטעמים טכנולוגיים אחרים לפי שיקול דעת Meetly.
		Meetly רשאית להפסיק באופן מיידי את השירותים הניתנים לך, כולם או מקצתם, באופן זמני או קבוע, אם הדבר יהיה מחמת כוח עליון שאינו בשליטת Meetly, כדוגמת שריפה, הצפה, סכסוך עבודה, תאונה, מלחמה או פעולת טרור, או כיוצא באלה, או על פי הוראה של רשות מוסמכת. הוראות אלה אינן גורעות מזכות Meetly לבטל את הרשיון מעיקרו.
		במקרה Meetly מחליטה לסיים רשיון זה מכל סיבה שהיא שאינה מפורטת בתת סעיפי 10.2 לעיל, יתאפשר לך חודש שימוש נוסף (להלן: “החודש הנוסף “). במהלך החודש הנוסף הנך רשאי לבקש לקבל לידך את הנתונים שנאגרו במערכת. במקרה כזה Meetly תעשה מאמץ סביר, אך היא אינה מתחייבת, לספק גיבוי בסיסי בפורמט הקריא על ידי תוכנת אקסל. הגיבוי יכיל פרטים אישיים של הלקוחות (אך לא היסטורית רכישות וביקורים), מועדי פגישות עתידיות ופירוט עסקאות היסטוריות. החל מתום החודש הנוסף, לא תתאפשר כניסה למערכת ו/או צפיה במידע שנאגר במערכת וכל התוכן של הלקוח יימחק מחשבי Meetly. מובהר כי Meetly אינה מחויבת (אף שהיא רשאית) לשמור נתונים שלך לאחר ביטול הרשיון ו/או סיום השרות והנתונים שגובו על גבי המערכת יהיה באחריותך המלאה והבלעדית מרגע זה ואילך.
        <br />
<br />
12. ביטול הרשיון ו/או הפסקת השירות על-ידי הלקוח
		
		הנך רשאי להפסיק באופן מיידי את קבלת השירותים מMeetly, כולם או מקצתם, ו/או לבטל את הרשיון עם Meetly, לפי שיקול דעתך המוחלט. הנך מתחייב להודיע לMeetly  בכתב על רצונך להפסיק את קבלת השירות. הודעה על סיום הרשיון על ידך תהיה במכתב רשום או באמצעות דואר אלקטרוני לפי פרטי ההתקשרות הקבועים ברשיון זה (להלן: “הודעת סיום ההתקשרות“).
		במקרה של הפסקת השירות על ידך, הנך רשאי לבקש לקבל לידך את הנתונים שגובו במערכת עד מועד קבלת הודעת סיום ההתקשרות על ידי Meetly וזאת תוך חודש ימים מיום מתן הודעת סיום ההתקשרות. בחלוף חודש מיום מתן הודעת סיום ההתקשרות על ידך, הנתונים שגובו על גבי המערכת יהיו באחריותך המלאה והבלעדית.
		אין במשלוח הודעת סיום ההתקשרות על ידך כאמור כדי לגרוע מחובתך לתשלום יתרת החוב לפי דמי המנוי ולא יתבצע החזר תשלום יחסי בגין החודש הקלנדרי בה נמסרה על ידך הודעת סיום ההתקשרות.


        <br />
        <br />



13. הוראות נוספות הנוגעות לשירותים מסוימים שמספקת המערכת
		מבלי לגרוע מכל הוראה אחרת בהסכם זה, התנאים הבאים יחולו על השירותים שלהלן:
		המערכת מכילה כלים מסוימים שמטרתם לאפשר ללקוח להוסיף אלמנטים עיצוביים לרבות תמונות, גרפיקות, וידאו וכדומה, לממשקי המערכת. מובהר כי Meetly לא תהא אחראית לכל תוכן ו/או תוסף אשר ישולב בממשקי המערכת על ידך.
		הנך אחראי באופן בלעדי על כל תוכן המועבר באמצעות המערכת ולא יעשה בה כל שימוש אשר עלול לכלול התנהגות האסורה על פי הדין.
		המערכת אינה מספקת מערכת הנהלת חשבונות ואינה מחליפה את הנהלת החשבונות המתחייבת על פי הוראות ניהול הספרים וחוקי המדינה.
        <br />
        <br />

14. דין ומקום שיפוט
		הדין החל על תנאי שימוש אלה זה הינו הדין ישראלי. בתי המשפט המוסמכים לדון בסכסוכים הנובעים מהסכם זה יהיו בתי המשפט בתל-אביב יפו ובמחוז המרכז בלבד.
		תנאי השימוש בתכנה עשויים להשתנות מעת לעת לפי שיקול דעתה הבלעדי של Meetly וללא מתן הודעה מראש והם יחייבו החל ממועד פרסומם באתר Meetly . שימוש בתכנה ו/או התקנת עדכונים לתכנה ו/או חידוש המינוי, שיעשו לאחר הכנסת השינויים בתנאי השימוש, יהוו הסכמה ואישור מצדך לתנאי הרישיון החדשים.

		אי-מימוש או איחור במימוש סמכות, זכות יתר או זכויות על פי רשיון זה על ידי צד כלשהו לא יהווה ויתור עליהן או כל צורה של השתק (מניעה) לגביהן.
		אינך רשאי להמחות או להעביר רשיון זה (לרבות את כל הזכויות והחובות שהוענקו על פיו) ללא הסכמה בכתב ומראש של Meetly .
		אתה וMeetly  מסכימים שרשיון זה הוא הצהרה מלאה ובלעדית של ההבנה ההדדית בין הצדדים והוא מבטל ובא במקום כל הסכמים ומסרים קודמים בעל-פה או בכתב הנוגעים לנושא רשיון זה. הנך מאשר ומסכים שההגבלות על האחריות, החבות והסעדים ברשיון זה הם תנאים יסודיים של הרשיון.
		אם הוראה כלשהי ברשיון זה תימצא על ידי ערכאה מוסמך כבלתי ניתנת לאכיפה, כל יתר הוראות הרשיון ימשיכו לחול ולחייב את הצדדים.
		כתובת הצדדים לצרכי משלוח הודעות בעניין הסכם זה הינם כתובתך כפי שמסרת ומופיעים ברשומות Meetly, וכתובתה של Meetly היא ארלוזרוב 51, תל אביב,. דואר אלקטרוני: support@meetly.co.il. כל הודעה שתישלח על ידי צד אחד למשנהו בדואר רשום לפי הכתובת הנ”ל או לכתובת הדוא”ל אשר מסרת ומופיעה ברשומות Meetly , תיחשב כאילו הגיעה ליעדה בתום 72 שעות מעת המסירה למשלוח בדואר רשום, או בתום 12 שעות, אם נשלחה בדואר אלקטרוני.
		Meetly תהי רשאית לשנות את תנאי השימוש מעת לעת לפי שיקול דעתו הבלעדי והמלא, ושינויים אלה יהיו תקפים ממועד פרסומם.
        <br />
    </div>
    <br />
    </Container>
}