import * as React from 'react'
import { BusinessApi } from '../api/business.api'
import { SessionContextStore } from './SessionContext'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import uuid from 'react-uuid'
import EventBus from '../services/eventbus/EventBus'

const defaultState = {
    id:'',
    name: '',
    phoneNumber: '',
    tracker: [], 
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'UPDATE_CURRENT_USER':
            return {...state , ...action.data }
        default:
            return state
    }
}

export const CustomerLogContextStore  = React.createContext(defaultState)

const CustomerLogContext = (props) => {    
    const [state, dispatch] = React.useReducer(reducer, defaultState)
    const sessionContext = React.useContext(SessionContextStore)

    const loadCustomer = async (id) => {
        EventBus.publish("SHOW_LOADING")
        const [resp1] = await Promise.all([
            BusinessApi.getBusinessCustomerByIdAuthorized(sessionContext.user,id)
        ])
        EventBus.publish("HIDE_LOADING")
        if (resp1.ok) {
            dispatch({type: 'UPDATE_CURRENT_USER', data: { ...resp1.data[0] }})
        }
        else {
            EventBus.publish("SHOW_ALERT", {text: 'שגיאה במהלך טעינה', status: 'error'})
        }
    }

    const updateCustomer = async (data) => { 
        EventBus.publish("SHOW_LOADING")
        let photo = {}
        if (data.photo) {
            let suffix = data.photo.file.name.split(".")[1]  
             photo = await BusinessApi.uploadImageToCustomer(sessionContext.user, state.id, uuid() + "."+ suffix , data.photo.file)
             photo.data = {...photo.data, isDefault: false}
        }
        else {

             photo = {data : { url:"https://firebasestorage.googleapis.com/v0/b/zuzu-d3c7c.appspot.com/o/empty_note.png?alt=media&token=46c1d79f-0685-4926-a011-0aa062cd0fe0",
                               isDefault: true,
                               imageName:'default'}}
        }
        const resp = await BusinessApi.addCustomerNote(sessionContext.user, state.id, {...data, photo: photo.data.url, imageName: photo.data.imageName, isDefault:photo.data.isDefault })
        EventBus.publish("HIDE_LOADING")
        if (resp.ok) {
            EventBus.publish("SHOW_ALERT", {text: 'הערה חדשה נוספה בהצלחה', status: 'success'})
        }
        else {
            EventBus.publish("SHOW_ALERT", {text: 'שגיאה במהלך הוספת הערה', status: 'error'})
        }
    }

    const deleteCustomerNote = async (noteId, imageName, isDefault) => {
        EventBus.publish("SHOW_LOADING")
        const resp = await BusinessApi.deleteCustomerNote(sessionContext.user, state.id, noteId, imageName, isDefault)
        EventBus.publish("HIDE_LOADING")
        if (resp.ok) {
            EventBus.publish("SHOW_ALERT", {text: 'הערה נמחקה בהצלחה', status: 'success'})
        }
        else {
            EventBus.publish("SHOW_ALERT", {text: 'שגיאה במהלך מחיקת הערה', status: 'error'})
        }
    }

    return (
       <CustomerLogContextStore.Provider value={{...state, dispatch,loadCustomer,updateCustomer,deleteCustomerNote}}>
            {props.children}
       </CustomerLogContextStore.Provider>
    )
}

export default CustomerLogContext