import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { BusinessApi } from '../api/business.api'
import EventBus from '../services/eventbus/EventBus'
import { firebaseAuth } from '../services/firebase/firebase.config'

const defaultState = {
    user: {
        uid: "",
        email: "null",
        name: ''
    },
    business: {

    },
    claims: {

    }
}


const reducer = (state, action) => {
    switch(action.type) {
        case 'SET_BUSINESS':
            return {...state, business: action.data}
        case 'SET_USER':
            return {...state , user: action.data}
        case 'SET_USER_CLAIMS':
            return {...state , claims: action.data}
        default:
            return state
    }
}

const reducerWrap = (state, action) => {
    const newState = reducer(state, action)
    localStorage.setItem('sessionContext', JSON.stringify({...newState, claims: {}}))
    return newState
}


export const SessionContextStore = React.createContext(defaultState)


const SessionContext = (props) => {
    const history = useHistory()
    const location = useLocation()
    const [state, dispatch] = React.useReducer(reducerWrap, defaultState, () => {
        let savedUser = localStorage.getItem("sessionContext")
        try {
            savedUser = JSON.parse(savedUser)
        }
        catch(e) {}

        return savedUser || defaultState
    })

    const getBusiness = async () => {
        if(state.user.uid) {
            EventBus.publish("SHOW_LOADING")
            const data = await BusinessApi.getBusinessPublicDataAuthorized(state.user)
            dispatch({ type: "SET_BUSINESS", data: data.data })
            EventBus.publish("HIDE_LOADING")
        }
    }

    React.useEffect(() => {
        if(!location.pathname.includes("blocked") && state && state.business && state.business.blocked) {
            history.push("/blocked")
        }
    }, [state.business, location])

    React.useEffect(() => {
        getBusiness()
    }, [state.user])

    React.useEffect(() => {
        if(firebaseAuth.currentUser != null) {
            firebaseAuth.currentUser.getIdTokenResult(true).then(idTokenResult => {
                dispatch({ type: "SET_USER_CLAIMS", data: {
                    tier: idTokenResult.claims.tier
                }})
            })
        }
    }, [firebaseAuth.currentUser])

    return (
        <SessionContextStore.Provider value={{...state, dispatch, getBusiness}}>
            {props.children}
        </SessionContextStore.Provider>
    )
}

export default SessionContext