import { lazyWithPreload } from "react-lazy-with-preload";
import {
    BrowserRouter as Router, Redirect, Route, Switch
} from "react-router-dom";
import './App.css';
import { Alerts } from './components/alerts/Alerts';
import { firebaseAuth, firebaseMessaging } from './services/firebase/firebase.config';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { experimental_extendTheme as extendTheme} from '@mui/material/styles';
import React, { Suspense } from 'react';
import CookieConsent from "react-cookie-consent";
import { Loading } from './components/alerts/Loading';
import BusinessFormContext from './context/businessFormContext';
import GuestContext from './context/guestContext';
import SessionContext from './context/SessionContext';
import { CookiePolicy } from './pages/public/CookiePolicy';
import { LoginProtectedRoute } from './services/LoginProtectedRoute';
import EventBus from "./services/eventbus/EventBus";
import { onMessage } from "firebase/messaging";
import { PrivacyPolicy } from "./pages/public/PrivacyPolicy";
import { JustTermsAndAggrement } from "./pages/public/JustTermsAndAgreement";
import CustomerLogContext from "./context/customerLogContext";

import { GuestProtectedRoute } from "./services/GuestProtectedRoute";

const BusinessOwnerHomePage = lazyWithPreload(() => import('./pages/BusinessOwnerHomePage').then(module => ({ default: module.BusinessOwnerHomePage }) ));
const BusinessInsights = lazyWithPreload(() => import("./pages/BusinessInsights").then(module => ({ default: module.BusinessInsights }) ));
const BusinessCusotmersPage = lazyWithPreload(() => import("./pages/BusinessCustomersPage").then(module => ({ default: module.BusinessCusotmersPage }) ));
const BusinessCustomersLog = lazyWithPreload(() => import("./pages/BusinessCustomersLog").then(module => ({ default: module.BusinessCustomersLog }) ));
const OwnerFlowScreen = lazyWithPreload(() => import("./components/ownerFlow/ownerFlowScreen/ownerFlowScreen").then(module => ({ default: module.OwnerFlowScreen }) ));
const NotFoundFage = lazyWithPreload(() => import("./pages/public/404page").then(module => ({ default: module.NotFoundFage }) ));
const LoginPage = lazyWithPreload(() => import("./pages/LoginPage").then(module => ({ default: module.LoginPage }) ));
const AppDrawer = lazyWithPreload(() => import("./components/navigation/AppDrawer").then(module => ({ default: module.AppDrawer }) ));
const BusinessPage = lazyWithPreload(() => import("./pages/public/BusinessPage").then(module => ({ default: module.BusinessPage }) ));
const BlockedBusinessPage = lazyWithPreload(() => import("./pages/BlockedBusinessPage").then(module => ({ default: module.BlockedBusinessPage }) ));
const PlansPage = lazyWithPreload(() => import("./pages/public/PlansPage").then(module => ({ default: module.PlansPage }) ));
const ClientAppointmentPage = lazyWithPreload(() => import("./pages/clients/ClientAppointmentPage").then(module => ({ default: module.ClientAppointmentPage }) ));
const ClientEntryPage = lazyWithPreload(() => import("./pages/clients/ClientEntryPage").then(module => ({ default: module.ClientEntryPage }) ));

const defaultTheme = {
    palette: {
      primary: {
        main: "#2F80ED", //customColorScheme.primaryColor,
        contrastText: "#ffffff"
      },
      secondary: {
          main: "#301e41" // customColorScheme.secondary
      },
      custom: {
          main: "#56CCF2"
      }
    },
    typography: {
      fontFamily: "Assistant"
    },
  }

function App() {
    const [theme, setTheme] = React.useState(() => {
        try {
            const savedTheme = localStorage.getItem("theme")
            if(savedTheme) 
                return extendTheme(JSON.parse(savedTheme))
            else 
                return extendTheme(defaultTheme)
        }
        catch(e) {
            return extendTheme(defaultTheme)
        }  
    })

    React.useEffect(() => {
        BusinessOwnerHomePage.preload()
        BusinessInsights.preload()
        BusinessCusotmersPage.preload()
        OwnerFlowScreen.preload()
        LoginPage.preload()

        return firebaseAuth.onAuthStateChanged((user) => {
            if(!user) {
                if( 
                    !(  window.location.pathname.startsWith("/business") || 
                        window.location.pathname.startsWith("/login") || 
                        window.location.pathname.startsWith("/clients") || 
                        window.location.pathname.startsWith("/plans") || 
                        window.location.pathname.startsWith("/cookiePolicy") || 
                        window.location.pathname.startsWith("/termsAndAgreement") || 
                        window.location.pathname.startsWith("/privacyPolicy") || 
                        window.location.pathname.startsWith("/404"))
                )
                    window.location.replace("/login")
            }
        })
    }, [])

    React.useEffect(() => {
        if(firebaseMessaging != null)
            onMessage(firebaseMessaging, (payload) => {
                EventBus.publish('SPECIAL_SHOW_ALERT', { text: payload.notification.body, status: 'info' })
            })
    }, [])

    React.useEffect(() => {
        const sub = EventBus.subscribe("BUSINESS_LOADED", (business) => {
            if(business && business.colorScheme) {
                defaultTheme.palette.primary.main = business.colorScheme.primaryColor || defaultTheme.palette.primary.main
                defaultTheme.palette.secondary.main = business.colorScheme.secondaryColor || defaultTheme.palette.secondary.main
                localStorage.setItem("theme", JSON.stringify(defaultTheme))
                setTheme(extendTheme({...defaultTheme}))
            }
        })

        return sub.unsubscribe
    }, [])


  return (<>
  <CssVarsProvider theme={theme}>
    <Alerts />
    <Loading />
    <CookieConsent
        location="bottom"
        buttonText="אני מסכים"
        cookieName="cookieConsent"
        style={{ background: "linear-gradient(to top, #000428, #004e92)" }}
        buttonClasses="text-sm"
        buttonStyle={{ color: "white", backgroundColor: 'transparent'}}
        expires={365}>
        <div className='text-xs'>
            האתר משתמש בעוגיות על מנת לשפר את חווית המשתמש.
            <a className='mr-1 underline' href="/cookiePolicy">למסמך פוליסת עוגיות לחץ כאן</a>
        </div>
        </CookieConsent>
    <Router>
        <Switch>
            <>
            <SessionContext>
                  <Route exact path={'/login'}>
                    <Suspense fallback={<div />}>
                        <LoginPage />
                    </Suspense>
                  </Route>

                  <Route exact path={'/plans'}>
                    <Suspense fallback={<div />}>
                        <PlansPage />
                    </Suspense>
                  </Route>

                  <Route exact path={'/404'}>
                    <Suspense fallback={<div />}>
                        <NotFoundFage />
                    </Suspense>
                  </Route>

                  <Suspense fallback={<div />}>
                    <AppDrawer />
                  </Suspense>                  

                  <LoginProtectedRoute exact path={'/customers'}>
                    <Suspense fallback={<div />}>
                        <BusinessCusotmersPage />
                    </Suspense>
                  </LoginProtectedRoute>

                  <CustomerLogContext>
                    <LoginProtectedRoute exact path={'/customers/:id'}>
                        <Suspense fallback={<div />}>
                            <BusinessCustomersLog />
                        </Suspense>
                    </LoginProtectedRoute>
                  </CustomerLogContext>
                  
                  <LoginProtectedRoute exact path={'/analytics'}>
                    <Suspense fallback={<div />}>
                        <BusinessInsights />    
                    </Suspense>
                  </LoginProtectedRoute>

                  <LoginProtectedRoute exact path={'/register'}>
                    <Suspense fallback={<div />}>
                        <BusinessFormContext>
                            <OwnerFlowScreen />    
                        </BusinessFormContext>
                    </Suspense>
                  </LoginProtectedRoute>

                  
                  <LoginProtectedRoute exact path={'/blocked'}>
                    <Suspense fallback={<div />}>
                        <BlockedBusinessPage />
                    </Suspense>
                  </LoginProtectedRoute>

                  <LoginProtectedRoute exact path={'/home'}>
                    <Suspense fallback={<div />}>
                        <BusinessOwnerHomePage />
                    </Suspense>
                  </LoginProtectedRoute>
            </SessionContext>
            
            <GuestContext>
                <Route exact path={'/clients/:id'}>
                    <Suspense fallback={<div />}>
                        <ClientEntryPage />
                    </Suspense>
                </Route>
                <GuestProtectedRoute exact path={'/clients/appts/:id'}>
                    <Suspense fallback={<div />}>
                        <ClientAppointmentPage />
                    </Suspense>
                </GuestProtectedRoute>
                <Route path={'/business/:businessId'}>
                    <Suspense fallback={<div />}>
                        <BusinessPage />
                    </Suspense>
                </Route>
            </GuestContext>

            <Route path={'/privacyPolicy'} component={PrivacyPolicy} />
            <Route path={'/termsAndAgreement'} component={JustTermsAndAggrement} />
            <Route path={'/cookiePolicy'} component={CookiePolicy} />

            <Route exact path={"/"}>
                <Redirect to={"/login"} />
            </Route>
            </>
        </Switch>
    </Router>
    </CssVarsProvider>
    </>
  );
}

export default App;




