import { Container } from "@mui/material"


export const PrivacyPolicy = () => {
    return <Container>
        <div className="text-center font-bold text-underline text-4xl mt-4 mb-4">מדיניות פרטיות</div>
        א. הקדמה
        <br />
		 הפרטיות של המבקרים באתר שלנו חשובה לנו מאוד, ואנחנו מחויבים לשמירה עליה. המדיניות הזו מסבירה מה נעשה עם הפרטים האישיים שלכם.
		ההסכמה לשימוש שלנו בעוגיות בהתאם לתנאים של מדיניות זו בזמן הביקור הראשון באתר שלנו, מאפשרת לנו להשתמש בעוגיות בכל פעם שאתם מבקרים באתר.
        <br />
ב. קרדיט
המסמך הזה נוצר בעזרת תבנית של SEQ Legal (seqlegal.com) ושונתה על ידי Website Planet (www.websiteplanet.com)
<br />
ג. איסוף פרטים אישיים
ייתכן שהסוגים הבאים של פרטים אישיים ייאספו, יאוחסנו, ויעשה בהם שימוש:
<br />
2. סוג המידע אשר נאסף ומטרת איסופו
כן מובהר, כי רישום למערכת בכל אמצעי, לרבות, רישום דרך האינטרנט באתר החברה ו/או באפליקציה ו/או בחיוג טלפוני על ידי המשתמש ו/או שימוש במערכת, כולם או חלקם, מהווה הסכמה מפורשת של המשתמש לתנאי מדיניות פרטיות זו ותנאי השימוש. סוג המידע אשר נאסף ומטרת איסופו:
<br />
2.1 החברה אוספת ושומרת במאגריה, מידע מסוים ביחס למשתמש על מנת שהמערכת תוכל לספק למשתמש את האפשרות לקביעת תור אצל החברה, בהתאם טיב והיקף השירות שאותו הוא מעוניין להזמין. מידע זה כולל:
<br />
2.1.1 רישום ויצירת קשר:  במסגרת ההרשמה המשתמש נדרש למסור מידע אישי ומזהה כדוגמת - שם, כתובת, דרכי התקשרות, מספר טלפון וכתובת דואר אלקטרוני אשר ישמרו במאגר המידע של החברה לצורך יצירת קשר עמך. הנך מצהיר כי הפרטים שמילאת הם נכונים והם על אחריותך בלבד. אין החברה נושאת באחריות בגין פרטים אישיים שיתגלו שאינם נכונים או מדויקים. הסליקה והתשלום יעשו בבית העסק של החברה על ידי סולק צד ג' ועל כן פרטי האשראי או התשלומים שיוזנו על ידי המשתמש אינם נשמרים במאגרי המערכת. ייתכן כי החברה תידרש לאמת את זהותך בהתאם למידע שנמסר על-ידך.
<br />
2.1.2 נתונים אנונימיים:  בעת השימוש במערכת (באפליקציה או באתר החברה), החברה אוספת מידע אנונימי, ללא כל זיקה לשימוש בשירות מסוים של החברה. מידע זה כולל מידע טכני וסטטיסטי, כגון: סוג מכשירך, דגם, חומרה, מערכת ההפעלה, ספק שירותי האינטרנט, מידע סטטיסטי הקשור להיקף השימוש במערכת לרבות תאריך ושעת שימוש, משך שימוש, תדירות השימוש, היסטוריית שימושים, העדפות שירותים, זמני מתן השירות, מחיר ועוד. החברה תהא רשאית לאסוף ו/או לאגור את הנתונים הללו, לפלחם ולנתחם לשם מתן שירותים, לרבות לצורך הצעה למתן שירותים נוספים. הנך רשאי להורות בכל עת על ביטול איסוף הנתונים האנונימיים אודותיך, זאת באמצעות ממשק האפליקציה או באמצעות יצירת קשר עמנו כפי שיפורט להלן.
<br />
2.1.3 נתונים אישיים:  בעת השימוש במערכת (באפליקציה או באתר החברה), החברה גם אוספת מידע שאינו אנונימי, בקשר עם הזמנת השירותים אצל החברה. הנך נותן את הסכמתך, כי החברה תהא רשאית לאסוף ו/או לאגור את הנתונים הללו, לפלחם ולנתחם, לצורך מתן השירותים, לרבות לצורך הצעה למתן שירותים נוספים. הנך רשאי להורות בכל עת על ביטול איסוף הנתונים האישיים אודותיך, זאת באמצעות ממשק האפליקציה או באמצעות יצירת קשר עמנו כפי שיפורט להלן.
<br />
2.1.4 כתובת פרוטוקול אינטרנט ("IP"):  בעת השימוש במערכת החברה תאסוף את כתובת ה- IP. בין השאר, החברה תגביל את השימוש במוצר על פי כתובת IP זהה.
<br />
2.1.5 מיקום:  לצורך הפעלת השימוש במערכת נעשה שימוש במיקום הגיאוגרפי של מכשירך, מידע זה מסופק לחברה ישירות מהמשתמש, ממכשירו באופן אוטומטי (על-ידי קואורדינאטות הנשלחות מהטלפון הסלולארי, שירותי מיקום GPS, השימוש בכתובת IP ועוד) ואף מצדדים שלישיים העובדים בשיתוף פעולה עם החברה. המשתמש מאשר בזאת כי ידוע לו ומובהר כי החברה עושה שימוש במידע זה לצורך מתן השירות והשימוש במערכת, הכולל נתונים שאינם אנונימיים, והינו נותן בזה את הסכמתו כי החברה תעשה בהם שימוש בין אם בעצמה או בשיתוף עם צדדים שלישיים.
<br />
2.1.6 דיוור ישיר:  בעת ההרשמה ומסירת הפרטים האישיים (לרבות הרפואיים) לחברה, נותן המשתמש את הסכמתו לקבלת דיוור ישיר כהגדרתו בחוק הגנת הפרטיות, תשמ"א-1981 (להלן: "דיוור ישיר") ובהתאם להוראות תיקון מס' 40 לחוק התקשורת (בזק ושידורים), התשמ"ב-1982), ולשימוש בפרטיו לשם כך. החברה תהא רשאית לעשות שימוש במידע המצוי ברשותה לצורך דיוור ישיר ו/או להעביר פרטים אודות המשתמש לצדדים שלישיים כמפורט במדיניות פרטיות זו ובתנאי השימוש לצורך ביצוע פניות בדיוור ישיר על-ידו. במידה ותהיה מעוניין להסיר עצמך מרשימת הדיוור לאחר הרישום, תוכל לעשות זאת בקלות בהודעה חוזרת במסגרת הדיוור הישיר ככל שיעשה באמצעים אלקטרוניים ו/או בפניה לחברה ובמקרה זה תסיר החברה את פרטיך מרשימת נמעני הדיוור הישיר המשמשת לצורך דיוור ישיר. אין בהסרת עצמך מרשימת הדיוור הישיר כדי לשלול קבלת מסרים אחרים, כגון מסרי שירות ו/או דברי פרסומת. אם הנך מעוניין בהסרת פרטיך מרשימת לקוחות החברה או להימנע מדיוור ישיר או מקבלת פניות מאת החברה באופן מוחלט, תוכל לעשות זאת על דרך מחיקת פרטיך כמשתמש בכלל.
<br />
2.2 מובהר בזאת, כי לא חלה על המשתמש כל חובה שבדין למסור מידע כאמור לעיל, וכן כי מסירת מידע כאמור תלויה ברצונו ובהסכמתו של המשתמש. עם זאת, מילוי חלק מהשדות בהליך הרישום ואיסוף נתונים עשוי להוות תנאי הכרחי לשימוש במערכת. המשתמש מצהיר ומתחייב בזאת, כי כל מידע שימסור ו/או יעדכן, הנו נכון אמין ומדויק וכן כי ביצוע הרישום הינו אישי ולא בשם ו/או עבור צדדים שלישיים, למעט במקרים בהם הורשה לעשות כן במפורש.
<br />
לפני שתחשפו בפנינו את הפרטים האישיים של אדם אחר, עליכם לבקש את הסכמת האדם הזה גם עבור השיתוף וגם עבור העיבוד של אותם הפרטים האישיים לפי מדיניות זו
<br />
ד. שימוש בפרטים האישיים שלכם
<br />
בפרטים אישיים שנמסרו לנו דרך האתר שלנו ייעשה שימוש למטרות שצוינו במדיניות זו, או בעמודים הרלוונטיים שבאתר. ייתכן שנשתמש בפרטים האישיים שלכם למטרות הבאות:
<br />
		ניהול האתר והעסק שלנו;
        <br />
		התאמה האישית של האתר עבורכם;
        <br />
		לאפשר לכם להשתמש בשירותים הזמינים באתר שלנו;
        <br />
		שליחה של סחורות שרכשתם דרך האתר;
        <br />
		אספקה של שירותים שרכשתם דרך האתר;
        <br />
		שליחה של הצהרות, חשבונות עסקה, ותזכורות תשלום אליכם, ואיסוף תשלומים מכם.
        <br />
		לשלוח לכם הודעות מסחריות שאינן שיווקיות;
        <br />
		לשלוח לכם התראות בדואר אלקטרוני שביקשתם באופן ספציפי;
        <br />
		שליחה של הניוזלטר שלנו אליכם בדואר אלקטרוני, אם ביקשתם זאת (אתם יכולים להודיע לנו בכל עת שכבר אין לכם צורך בניוזלטר);
        <br />
		שליחה של מסרים שיווקיים שקשורים לעסק שלנו והעסקים של חברות צד ג’ שנבחרו בקפידה שלדעתנו עשויות לעניין אתכם, בדואר או, במקרים שבהם הסכמתם לכך ספציפית, בדואר האלקטרוני, או באמצעות טכנולוגיות דומות (אתם יכולים ליידע אותנו בכל עת אם אתם כבר לא מעוניינים במסרים שיווקיים);
        <br />
		אספקה של מידע סטטיסטי בנוגע למשתמשים שלנו לצד ג’ (אבל צד ג’ זה לא יוכל לזהות אף משתמש בודד לפי המידע);
        <br />
		טיפול בבירורים ותלונות שהוגשו על ידכם או נוגעות אליכם וקשורות לאתר שלנו;
        <br />
		לשמור על האתר שלנו מאובטח ולמנוע הונאה;
        <br />
		אימות של היענות לתנאי השירות המכתיבים את אופן השימוש באתר (כולל ניטור של הודעות פרטיות שנשלחו דרך שירות ההודעות הפרטיות של האתר שלנו);
        <br />
		ושימושים אחרים.
        <br />
אם תמסרו לנו מידע אישי לפרסום באתר שלנו, אנחנו נפרסם את המידע ונשתמש בו בדרכים אחרות בהתאם לרישיון שתספקו לנו.
ניתן להשתמש בהגדרות הפרטיות שלכם כדי להגביל את הפרסום של המידע שלכם באתר שלנו, וניתן לשנות אותן בעזרת בקרות הפרטיות שבאתר.
אנחנו לא נספק את הפרטים האישיים שלכם ללא הסכמתכם לכל צד ג’ שהוא, לצורך השיווק הישיר שלו, או של כל צד ג’ אחר.
<br />
ה. חשיפת פרטים אישיים
<br />
אנחנו עשויים למסור את הפרטים האישיים שלכם לכל אחד מהעובדים, המנהלים, המבטחים, היועצים המקצועיים, הסוכנים, הספקים, או קבלני המשנה שלנו, במידה סבירה וכנדרש למטרות המצוינות במדיניות זו.
אנחנו עשויים למסור את הפרטים האישיים שלכם לכל חברה בקבוצת החברות שלנו (זה אומר כל חברות הבת שלנו, חברת הגג שלנו, וכל חברות הבת שלה) במידה סבירה וכנדרש למטרות המצוינות במדיניות זו.
<br />
אנחנו עשויים למסור את הפרטים האישיים שלכם:
<br />
		לפי מה שנדרש מאתנו על פי חוק;
        <br />
		בהקשר של כל הליך משפטי קיים או עתידי;
        <br />
		על מנת לבסס, להפעיל, או להגן על זכויותינו המשפטיות (כולל אספקה של מידע לאחרים למטרות מניעת הונאה והפחתה של סיכון אשראי);
        <br />
		לרוכש (או הרוכש הפוטנציאלי) של כל עסק או רכוש שאנחנו רוכשים (או שוקלים לרכוש);
        <br />
		ולכל אדם שאנחנו מאמינים במידה סבירה שעשוי לפנות לבית דין או לכל רשות מוסמכת לצורך מסירה של הפרטים האישיים בהם סביר לדעתנו שאותו בית דין או רשות יורה על מסירה של פרטים אישיים אלה.
        <br />
למעט על פי הכתוב במדיניות זו, אנו לא נספק את הפרטים האישיים שלכם לצד ג’.
<br />
ו. העברות נתונים בינלאומיות
		ייתכן שמידע שאנחנו אוספים יאוחסן, יעובד, ויועבר בין כל אחת מהמדינות בהן אנו פועלים, על מנת לאפשר לנו להשתמש במידע בהתאם למדיניות זו.
		ייתכן שמידע שאנחנו אוספים יועבר למדינות הבאות, להן אין חוקי הגנת נתונים התואמים את אלה הפועלים באזור הכלכלי האירופי: ארצות הברית של אמריקה, רוסיה, יפן, סין, והודו.
		פרטים אישיים שאתם מפרסמים באתר שלנו או מוסרים לפרסום באתר שלנו עשויים להיות זמינים, באמצעות האינטרנט, מסביב לעולם. אנחנו לא יכולים למנוע את השימוש או השימוש לרעה במידע הזה בידי אחרים.
		אתם מביעים הסכמה להעברות הפרטים האישיים המתוארות בסעיף ו’ זה.
        <br />
ז. שמירת פרטים אישיים
		סעיף זה מפרט את תהליך ומדיניות שמירת הנתונים שלנו, המתוכננים לעזור להבטיח שאנחנו נענים לחובות המשפטיות שלנו הנוגעות לשמירה ולמחיקה של פרטים אישיים.
		פרטים אישיים שאנחנו מעבדים עבור כל מטרה או מטרות, לא יישמרו יותר מכמה שנדרש עבור מטרה או מטרות אלה.
		על אף ההוראות האחרות בסעיף ז’, אנו נשמור מסמכים (כולל מסמכים אלקטרוניים) המכילים נתונים אישיים:
		לפי מה שנדרש מאתנו על פי חוק;
		אם לדעתנו המסמכים עשויים להיות רלוונטיים לכל הליך משפטי מתמשך או פוטנציאלי;
		על מנת לבסס, להפעיל, או להגן על זכויותינו המשפטיות (כולל אספקה של מידע לאחרים למטרות מניעת הונאה והפחתה של סיכון אשראי).
        <br />
ח. אבטחת הפרטים האישיים שלכם
		אנחנו ננקוט משנה זהירות ארגוני וטכני סביר על מנת למנוע את האבדן, השימוש לרעה, או השינוי של הפרטים האישיים שלכם.
		אנחנו נאחסן את כל הפרטים האישיים שאתם מספקים בשרתים מאובטחים
		כל ההעברות הכספיות הממוחשבות שבוצעו דרך האתר שלנו יהיו מוגנות באמצעות טכנולוגיית הצפנה.
		אתם מכירים בכך שהשידור של מידע על פני האינטרנט אינו מאובטח מעצם היותו, ואנחנו ללא יכולים להבטיח שנתונים שנשלחים דרך האינטרנט יהיו מאובטחים.
		אתם אחראים על שמירת הסיסמא בה אתם משתמשים לקבלת גישה לאתר שלנו חסויה; אנחנו לא נבקש מכם את הסיסמא שלכם (למעט בזמן ההתחברות לאתר שלנו).
        <br />
ט. תיקונים
אנחנו עשויים לעדכן מדיניות זו מעת לעת באמצעות פרסום של גרסה חדשה באתר שלנו. עליכם לבדוק את העמוד הזה מדי פעם על מנת לוודא שאתם מבינים כל שינוי שנעשה במדיניות זו. אנחנו עשויים להודיע לכם על שינויים במדיניות זו בהודעת דואר אלקטרוני או דרך מערכת ההודעות הפרטיות שבאתר שלנו.
<br />
י. הזכויות שלכם
אתם יכולים להורות לנו לספק לכם כל פרט מפרטיכם האישיים שאנו מחזיקים הנוגעים אליכם; אספקת פרטים אלה תהיה כפופה לתנאים הבאים:
		אספקה של ראיות הולמות עבור הזהות שלכם - מספר תעודת זהות\מספר עוסק אנחנו עשויים לסרב למסור פרטים אישיים שאתם מבקשים עד למידה המורשית על פי חוק.
אתם יכולים להורות לנו בכל עת לא לעבד את הפרטים האישיים שלכם לצורכי שיווק.
בפועל, בדרך כלל תבטאו הסכמה מראש לשימוש שלנו בפרטים האישיים שלכם למטרות שיווק, או שאנחנו נספק לכם אפשרות לבחור שלא יעשה בפרטים האישיים שלכם שימוש למטרות שיווקיות.
<br />
יא. אתרים צד ג’
האתר שלנו כולל קישורים חיצוניים, ופרטים על, אתרים צד ג’. אין לנו שליטה על, ואיננו אחראים על, מדיניות הפרטיות והנהלים של כל צד ג’.
<br />
יא. אתרים צד ג’
<br />
האתר שלנו כולל קישורים חיצוניים, ופרטים על, אתרים צד ג’. אין לנו שליטה על, ואיננו אחראים על, מדיניות הפרטיות והנהלים של כל צד ג’.
<br />
יב. עדכון מידע
אני הודיעו לנו אם יש צורך לתקן או לעדכן את הפרטים האישיים שאנו מחזיקים עליכם.
<br />
יג. עוגיות
<br />
האתר שלנו משתמש בעוגיות. עוגייה היא קובץ המכיל מזהה (מחרוזת של אותיות ומספרים) שנשלח על ידי שרת אינטרנט לדפדפן אינטרנט ומאוחסן בדפדפן. אז, המזהה נשלח בחזרה לשרת בכל פעם שהדפדפן מבקש מהשרת להציג דף אינטרנט. עוגיות יכולות להיות או עוגיות “עיקשות” או עוגיות “פעולה” (session): עוגייה עיקשת תאוחסן בדפדפן שלכם ותישאר בתוקף עד תאריך התפוגה שנקבע לה, אלא אם תימחק על ידי המשתמש לפני תאריך התפוגה; עוגיית פעולה, מאידך, תפוג בסוף זמן הגלישה הנוכחי שלכם, כשתסגרו את הדפדפן. בדרך כלל עוגיות לא כוללות מידע שמזהה משתמשים אישית, אבל פרטים אישיים שאנחנו מאחסנים הנוגעים עליכם יכולים להיות מקושרים למידע המאוחסן והמתקבל מתוך עוגיות. (בחרו בניסוח המדויק אנחנו משתמשים רק בעוגיות פעולה / רק בעוגיות עיקשות / גם בעוגיות פעולה וגם בעוגיות עיקשות באתר שלנו.(
		השמות של העוגיות בהן אנחנו משתמשים באתר שלנו, והמטרות לשמן הן בשימוש, מפורטות מטה:
		אנחנו משתמשים בשירותי Google Analytics ו-Adwords באתר שלנו כדי לזהות מחשב כשמשתמש (כללו את כל השימושים הקיימים לעוגיות באתר שלכם מבקר באתר / לעקוב אחרי משתמשים בזמן השימוש שלהם באתר / לאפשר לנו להשתמש בעגלת קניות באתר / לשפר את נוחות השימוש באתר / לנתח את השימוש באתר / לנהל את האתר / למנוע הונאה ולשפר את האבטחה של האתר / להתאים אישית את האתר לכל משתמש / להשתמש בפרסומות ממוקדות שעשויות לעניין במיוחד משתמשים מסוימים / תארו את המטרה/ות(;
		רוב האתרים מאפשרים לכם לסרב לשימוש בעוגיות—למשל:
		באינטרנט אקספלורר (גרסה 10) אתם יכולים לחסום עוגיות בעזרת הגדרות עקיפת הטיפול בעוגיות הזמינות באמצעות לחיצה על “כלים,” “אפשרויות אינטרנט,” “פרטיות,” ואז “מתקדם”;
		בפיירפוקס (גרסה 24) אתם יכולים לחסום את כל העוגיות באמצעות לחיצה על “כלים,” “אפשרויות,” “פרטיות,” ובחירה של “השתמש בהגדרות מותאמות אישית עבור היסטוריה” מתוך התפריט הנפתח, ואז ביטול הזימון של “קבל עוגיות מאתרים”;
		ובכרום (גרסה 29), אתם יכולים לחסום את כל העוגיות באמצעות כניסה לתפריט “התאמה אישית ובקרה,” ואז ללחוץ על “הגדרות,” “הצג הגדרות מתקדמות,” ו-“הגדרות תוכן,” ואז לבחור באפשרות “חסום אתרים מהגדרה של נתונים” תחת הכותרת “עוגיות.”
לחסימה של כל העוגיות תהיה השפעה שלילית על נוחות השימוש של אתרים רבים. אם תחסמו עוגיות, לא תוכלו להשתמש בכל האפשרויות שבאתר שלנו.
		אתם יכולים למחוק עוגיות שכבר מאוחסנות במחשב שלכם—למשל:
		בדפדפן אינטרנט אקספלורר (גרסה 10), אתם צריכים למחוק קבצי עוגיות ידנית (תוכלו למצוא הוראות לכך בכתובת http://support.microsoft.com/kb/278835 );
		בפיירפוקס (גרסה 24), אתם יכולים למחוק עוגיות באמצעות לחיצה על “כלים,” “אפשרויות,” ו-“פרטיות”, ואז לבחור “השתמש בהגדרות מותאמות אישית עבור היסטוריה”, וללחוץ על “הצג עוגיות,” ואז “הסר את כל העוגיות”;
		ובכרום (גרסה 29), אתם יכולים למחוק את כל העוגיות באמצעות כניסה לתפריט “התאמה אישית ובקרה,” ואז ללחוץ על “הגדרות,” “הצג הגדרות מתקדמות,” ו-“נקה נתוני גלישה,” ואז לבחור באפשרות “מחק עוגיות ונתונים אחרים של אתרים ותוספים,” ולבסוף ללחוץ “נקה נתוני גלישה.”
		למחיקה של עוגיות תהיה השפעה שלילית על נוחות השימוש של אתרים רבים.



</Container>
}