// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
import { getAnalytics, logEvent } from "firebase/analytics";
import { connectFirestoreEmulator, getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDtlZUGg96YXc9TBIlYfuw7p9qrh8PlkWI",
  authDomain: "zuzu-d3c7c.firebaseapp.com",
  projectId: "zuzu-d3c7c",
  storageBucket: "zuzu-d3c7c.appspot.com",
  messagingSenderId: "1057598939681",
  appId: "1:1057598939681:web:0c85560ca2367fff1b7960",
  measurementId: "G-7MRV81EWBJ"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);
export const firebaseAuth = getAuth(firebaseApp);

export const firestoreDb = getFirestore(firebaseApp);

export const firebaseStorage = getStorage(firebaseApp)

let messaging = null
try {
    messaging = getMessaging(firebaseApp);
}
catch(e) {}

export const firebaseMessaging = messaging

export const firebaseAnalytics = getAnalytics(firebaseApp);

export const firebaseMessagingKey = "BKcVLaErYWA1PUooUSRlpJ0U_xyjZ-ZBVlT1Z1LN0UF26k0pOyLJLOHunSE7HG3uVvp-izX9YLsf1ayYhxdV6nk"

export const firebaseCompact = firebase

export const logAnalyticEvent = (eventName, eventData) => {
    logEvent(firebaseAnalytics, eventName, eventData)
}

const db = getFirestore()

console.log(process.env.NODE_ENV)
if(window.location.hostname == "localhost" && process.env.NODE_ENV == "development") {
  connectFirestoreEmulator(db,"localhost",8081)
}

// enableIndexedDbPersistence(firestoreDb);