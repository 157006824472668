import React, { useContext } from 'react'
import { Redirect, Route, useParams } from 'react-router-dom'
import { GuestContextStore } from '../context/guestContext'

export const GuestProtectedRoute = ({children, ...rest}) => {
    const { id } = useParams()
    const guestContext = useContext(GuestContextStore)
    const isLoggedIn = (!!guestContext.guest.name) && (guestContext.guest.name != 'אורח')
    // const isLoggedIn = !!localStorage.getItem("guestContext") ? 
    //     JSON.parse(localStorage.getItem("guestContext")).guest.name != 'אורח' : false

    return (
        <Route exact {...rest}
          render={({ location }) => isLoggedIn ? (children) : (
              <Redirect
                to={{
                  pathname: `/clients/${id}`,
                  state: { from: location }
                }}
              />
            )
          }
        />)
}