import { Container } from "@mui/system"


export const CookiePolicy = () => {
    return <Container className="">
        <br />
        <div className="text-2xl mb-4 font-bold text-center">מדיניות קבצי עוגיות עבור Meetly</div>
        
        <div className="text-right ">

        Meetly משתמש בקבצי עוגיות כדי לשפר את חווית הגלישה שלך וכדי לספק לך תוכן ושירותים מותאמים אישית. על ידי שימוש באתר שלנו, אתה מסכים לשימוש שלנו בעוגיות בהתאם למדיניות זו.
        <br />
        מהן עוגיות?
        <br />
        קבצי עוגיות הם קבצי טקסט קטנים המאוחסנים במכשיר שלך כאשר אתה מבקר באתר אינטרנט. הם משמשים לזכור את ההעדפות והפעילויות שלך באתר, ולעזור לאתר לתפקד כראוי.
        <br />
        סוגי קבצי עוגיות בשימוש ב-Meetly
        <br />
        קבצי עוגיות חיוניים: קבצי עוגיות אלו נחוצים כדי שהאתר יפעל ולא ניתן לבטל אותם. הם משמשים כדי להבטיח תכונות בסיסיות באתר כגון ניווט בדפים וגישה לאזורים מאובטחים.
        <br />
        עוגיות ביצועים: עוגיות אלה אוספות מידע על אופן השימוש שלך באתר, כגון אילו דפים אתה מבקר ועל אילו קישורים אתה לוחץ. מידע זה משמש לשיפור ביצועי האתר ולשיפור חווית המשתמש שלך.
        <br />
        עוגיות פונקציונליות: עוגיות אלה זוכרות את ההעדפות והבחירות שלך, כגון השפה והאזור שלך, כדי לספק חוויה אישית יותר.
        <br />
        עוגיות שיווקיות: עוגיות אלה עוקבות אחר התנהגות הגלישה שלך כדי לספק לך פרסומות וחומרי קידום מכירות מותאמים אישית.
        <br />
        ניהול קבצי עוגיות:
        אתה יכול לנהל את העדפות העוגיות שלך דרך הגדרות הדפדפן שלך. רוב הדפדפנים מאפשרים לך לחסום או למחוק קבצי עוגיות, אך הדבר עשוי להשפיע על הפונקציונליות של Meetly.
        <br />

        מדיניות קבצי עוגיות זו עודכנה לאחרונה ב-01/02/2023. אנו שומרים לעצמנו את הזכות לבצע שינויים במדיניות זו בכל עת.
        </div>
        <br />
    </Container>
}