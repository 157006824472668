import * as React from 'react'

const defaultState = {
    guest: {
        phoneNumber: '',
        name: ''
    }
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'SET_GUEST_USER':
            return {...state , guest: action.data}
        default:
            return state
    }
}

const reducerWrap = (state, action) => {
    const newState = reducer(state, action)
    localStorage.setItem('guestContext', JSON.stringify(newState))
    return newState
}

export const GuestContextStore = React.createContext(defaultState)

const GuestContext = (props) => {    
    const [state, dispatch] = React.useReducer(reducerWrap, defaultState, () => {
        let savedUser = localStorage.getItem("guestContext")
        try {
            savedUser = JSON.parse(savedUser)
        }
        catch(e) {}

        return savedUser || defaultState
    })

    return (
        <GuestContextStore.Provider value={{...state, dispatch}}>
            {props.children}
        </GuestContextStore.Provider>
    )
}

export default GuestContext