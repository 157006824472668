import { Build } from "@mui/icons-material";
import moment from "moment";
import EventBus from "../services/eventbus/EventBus";
import { logAnalyticEvent } from "../services/firebase/firebase.config";
import { FirestoreApi } from "../services/firebase/firebase.service";


export class BusinessApi {

    static async isBusinessExists(user) {
        try {
            
            return { ok: true, data: await FirestoreApi.isBusinessExists(user.uid) }
        }
        catch(e) {
            console.error(e)
            return { ok: false}
        }
    }

    static async deleteAppoitment(user, appoitment) {
        
        const appoitmentDate = moment(appoitment.date).format("YYYY-MM-DD")
        appoitment.date = appoitmentDate
        const appoitmentNew = {...appoitment}
        delete appoitmentNew.phoneNumber
        delete appoitmentNew.userName
        try {
            return { ok: await FirestoreApi.deleteAppoitment(user, appoitmentNew) }
        }
        catch(e) {
            console.error(e)
            return { ok: false }
        }
    }

    static async disableDates(user, dateRangeObject) {
        try {
            return { 
                ok: await FirestoreApi.disableDates(user, { 
                    startDate: moment(dateRangeObject.startDate).format("YYYY-MM-DD"),
                    endDate: moment(dateRangeObject.endDate).format("YYYY-MM-DD") 
                })
            }
        }
        catch(e) {
            console.error(e)
            return { ok: false }
        }
    }

    static async releaseDates(userUid, dateRangeObject) {
        try {
            return { 
                ok: await FirestoreApi.releaseDates(userUid, { 
                    startDate: moment(dateRangeObject.startDate).format("YYYY-MM-DD"),
                    endDate: moment(dateRangeObject.endDate).format("YYYY-MM-DD") 
                })
            }
        }
        catch(e) {
            console.error(e)
            return { ok: false }
        }
    }

    static async getBusinessBlockedDaysNotAuthorized(businessId) {
        try {
            return { ok: true, data: await FirestoreApi.getBusinessBlockedDaysById(businessId) }
        }
        catch(e) {
            console.error(e)
            return { ok: false }
        }
    }

    static async addAppoitmentNotAuthorized(businessId, appoitment) {
        const appoitmentDate = moment(appoitment.date).format("YYYY-MM-DD")
        appoitment.date = appoitmentDate
        logAnalyticEvent("purchase", {...appoitment, businessId: businessId, value: appoitment.price })
        
        try {
            return { ok: await FirestoreApi.addAppoitment(businessId, appoitment) }
        }
        catch(e) {
            console.error(e)
            return { ok: false }
        }
    }

    static async getBusinessAppoitments(user) {
        try {
            return { ok: true, data: await FirestoreApi.getBusinessAppoitments(user.uid)}
        }
        catch(e) {
            console.error(e)
            return { ok: false}
        }
    }

    static async createBusiness(user, business) {
        try {
            if(business.logo.file) {
                business.logo = await FirestoreApi.uploadImageToBusiness(user, 'logo', business.logo.file)
            }
            else {
                business.logo = business.logo.data_url
            }
            // business.photo ? business.photo = await FirestoreApi.uploadImageToBusiness(user,'background',business.photo.file) : business.photo = ""

            const resp = await FirestoreApi.createBusiness(user, business)
            return { ok: true }
        }
        catch(e) {
            console.error(e)
            return { ok: false}
        }
    }

    static async getBusinessPublicDataAuthorized(user) {
        try {
            const [business, workers] = await Promise.all([
                FirestoreApi.getBusinessByUser(user),
                FirestoreApi.getBusinessWorkers(user.uid)
            ]) 
            business.workers = workers || []
            EventBus.publish("BUSINESS_LOADED", business)
            return { ok: business != null, data: business }
        }
        catch(e) {
            console.error(e)
            return { ok: false}
        }
    }

    static async getBusinessCustomersAuthorized(user) {
        try {
            const users = await FirestoreApi.getBusinessCustomers(user)
            return { ok: true, data: users }
        }
        catch(e) {
            console.error(e)
            return { ok: false}
        }
    }

    static async getBusinessCustomersAuthorized(user) {
        try {
            const users = await FirestoreApi.getBusinessCustomers(user)
            return { ok: true, data: users }
        }
        catch(e) {
            console.error(e)
            return { ok: false}
        }
    }
    static async getBusinessCustomerByIdAuthorized(user,customerId) {
        try {
            const users = await FirestoreApi.getBusinessCustomerById(user,customerId)
            return { ok: true, data: users }
        }
        catch(e) {
            console.error(e)
            return { ok: false}
        }
    }
    static async getBusinessCustomerNotesByIdAuthorized(user, customerId) {
        try {
            const notes = await FirestoreApi.getBusinessCustomerNotesById(user, customerId)
            return { ok: true, data: notes }
        }
        catch(e) {
            console.error(e)
            return { ok: false}
        }
    }

    static async getBusinessNotAuthorized(businessId) {
        try {
            const business = await FirestoreApi.getBusinessById(businessId)
            const workers = await FirestoreApi.getBusinessWorkers(business.uid)
            business.workers = workers || []
            console.log('business.workers', business.workers)
            EventBus.publish("BUSINESS_LOADED", business)
            return { ok: business != null, data: business }
        }
        catch(e) {
            console.error(e)
            return { ok: false}
        }
    }
    
    static async uploadImageToBusiness(user, imageName, imageFile) {
        try {
            const imageRef = await FirestoreApi.uploadImageToBusiness(user, imageName, imageFile)
            return { ok: true, data: imageRef }
        }
        catch(e) {
            console.error(e)
            return { ok: false }
        }
    }

    static async uploadImageToCustomer(user, id, imageName, imageFile) {
        try {
            const image = await FirestoreApi.uploadImageToCustomer(user, id, imageName, imageFile)
            return { ok: true, data: { url: image , imageName: imageName } }
        }
        catch(e) {
            console.error(e)
            return { ok: false }
        }
    }

    static async addCustomerNote(user,customerId,note) {
        try {
            await FirestoreApi.addCustomerNote(user, customerId, note)
            return { ok: true }
        }
        catch(e) {
            console.error(e)
            return { ok: false }
        }
    }


    static async deleteCustomerNote(user, customerId, noteId, imageName, isDefault) {
        try {
            await FirestoreApi.deleteCustomerNote(user, customerId, noteId, imageName, isDefault)
            return { ok: true }
        }
        catch(e) {
            console.error(e)
            return { ok: false }
        }
    }


}