import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import EventBus from '../../services/eventbus/EventBus';


export const Loading = () => {

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false)
    }

    React.useEffect(() => {
        const subscription1 = EventBus.subscribe("SHOW_LOADING", (text) => {
            setOpen(true)
        })

        const subscription2 = EventBus.subscribe("HIDE_LOADING", () => {
            setOpen(false)
        })

        return function cleanup () {
            subscription1.unsubscribe()
            subscription2.unsubscribe()
        }
    }, [])


    return <>
    <Dialog
        PaperProps ={{
            className:'box-none transparent',
        }}
        open={open}
        onClose={handleClose}>
            <CircularProgress />
    </Dialog>
    </>
}