import moment from 'moment';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { BusinessApi } from '../api/business.api';
import EventBus from '../services/eventbus/EventBus';
import { SessionContextStore } from './SessionContext';


export const defaultState = {
    businessName: '',
    phoneNumber:'',
    address:'',
    facebookLink:'',
    instagramLink:'',
    category: '',
    logo:'',
    appointmentMargin: 30,
    showPricesToCustomers: false,
    hasAcceptTerms: false,
    photo: '',
    colorScheme: {
        primaryColor: '#2F80ED'
    },
    services: [],
    openingHour: moment('8:00', "HH:mm"),
    closingHour: moment('17:00', "HH:mm"),
    daySpecialHours: {
        1: {
            openingHour: '8:00',
            closingHour: '17:00'
        },
        2: {
            openingHour: '8:00',
            closingHour: '17:00',
        },
        3: {
            openingHour: '8:00',
            closingHour: '17:00',
        },
        4: {
            openingHour: '8:00',
            closingHour: '17:00',
        },
        5: {
            openingHour: '8:00',
            closingHour: '17:00',
        },
        6: {
            openingHour: '8:00',
            closingHour: '17:00',
        },
        7: {
            openingHour: '8:00',
            closingHour: '17:00',
        }
    },
    days: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday'],
    workers:[]
    
}

const reducer = (state, action) => {
    switch(action.type) {
        case "UPDATE_FORM": 
            return {...state, ...action.data}
        default:
            return state
    }
}

export const BusinessFormContextStore = React.createContext(defaultState)

const BusinessFormContext = (props) => {
    const userContext = React.useContext(SessionContextStore)
    const [state, dispatch] = React.useReducer(reducer, defaultState, () => {
        if(userContext.business && Object.keys(userContext.business).length > 0) {
            return userContext.business
        }

        return defaultState
    })

    
    const history = useHistory()

    const createBusiness = async (finalStepData) => {
        EventBus.publish("SHOW_LOADING")
        let resp = await BusinessApi.createBusiness(userContext.user, {...state, ...finalStepData, hasAcceptTerms: true})
        EventBus.publish("HIDE_LOADING")
        if (resp.ok) {
            userContext.getBusiness()
            EventBus.publish('SHOW_ALERT', {text: "השלבים הסתיימו בהצלחה", status: 'success'})
            history.push('/home')
        }
        else {
            dispatch({ type: 'UPDATE_FORM', data: finalStepData })
            EventBus.publish('SHOW_ALERT', {text: "בעייה באחד השלבים נסה שנית", status: 'error'})
        }
        
    }

    return (
        <BusinessFormContextStore.Provider value={{...state, dispatch, createBusiness}}>
            {props.children}
        </BusinessFormContextStore.Provider>
    )
}

export default BusinessFormContext