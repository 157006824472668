import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, documentId, getDoc, getDocFromCache, getDocs, getDocsFromCache, limit, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { firebaseAuth, firebaseStorage, firestoreDb } from "./firebase.config";
import moment from "moment";
const md5 = require('md5');

export class FirestoreApi {

    static async getBusinessById(businessId) {
        const businessRef = collection(firestoreDb, 'business')
        const q = query(businessRef, where("businessId", "==", businessId), limit(1))
        const snapshot = await getDocs(q)
        if(!snapshot.empty) {
            return {...snapshot.docs[0].data(), uid: snapshot.docs[0].id}
        }
    }

    static async getBusinessWorkers(businessId) {
        const workersRef = collection(firestoreDb, `business/${businessId}/workers`)
        const snapshot = await getDocs(query(workersRef, where("active", "==", true)))
        let workersBusiness = []
        
        if(!snapshot.empty) {
            snapshot.forEach(workerBusinessDoc => {
                workersBusiness.push({ ...workerBusinessDoc.data(), uid: workerBusinessDoc.id })
            })
            
            workersBusiness = await Promise.all(workersBusiness.map(async workerDoc => {
                const fe = await getDoc(doc(firestoreDb, `business/${workerDoc.uid}`))
                return {...fe.data(), uid: workerDoc.uid}
            }))
        }
        
        return workersBusiness
    }

    static async getBusinessBlockedDaysById(businessId) {
        const businessRef = collection(firestoreDb, 'business')
        const q = query(businessRef, where("businessId", "==", businessId), limit(1))
        const snapshot = await getDocs(q)
        if(!snapshot.empty) {
            // console.log(`${snapshot.docs[0].ref.path}/appoitments/blocked`)
            const docRef = doc(firestoreDb, `${snapshot.docs[0].ref.path}/appoitments/blocked`) 
            return (await getDoc(docRef)).data()
        }
        return {}
    }

    static async getBusinessByUser(user) {
        const businessRef = doc(firestoreDb, `business/${user.uid}`)
        const snapshot = await getDoc(businessRef)
        return snapshot.data()
    }

    static async isBusinessExists(userUid) {
        const docRef = doc(firestoreDb, 'business', userUid)
        const docSnap = await getDoc(docRef)
        return docSnap.exists()
    }

    static async getBusinessPrivateData(userUid) {
        const docRef = doc(firestoreDb, 'business', userUid)
        const docSnap = await getDoc(docRef)
        if(docSnap.exists())
            return docSnap.data()
        else
            return null
    }

    static async getBusinessCustomerById(user,customerId)
    {
        const customersCollectionRef = collection(firestoreDb, 'business', user.uid, 'users')
        const docSnap = await getDocs(query(customersCollectionRef))
        const users = []
        docSnap.forEach(userDoc => {
            if(userDoc.id == customerId)
                users.push({id: userDoc.id, ...userDoc.data()})
        })
        return users
    }

    static async getBusinessCustomerNotesById(user,customerId)
    {
        const customersCollectionRef = collection(firestoreDb, 'business', user.uid, 'users', customerId,'notes')
        const docSnap = await getDocs(query(customersCollectionRef))
        const notes = []
        docSnap.forEach(userDoc => {
            notes.push({ id: userDoc.id , ...userDoc.data() })
        })
        return notes
    }

    static async getBusinessCustomers(user) {
        const customersCollectionRef = collection(firestoreDb, 'business', user.uid, 'users')
        const docSnap = await getDocs(query(customersCollectionRef))
        const users = []
        docSnap.forEach(userDoc => {
            users.push({ id: userDoc.id , ...userDoc.data() })
        })
        return users
    }

    static async updatePushNotificationsToken(user, token) {
        const today = moment().toDate();
        const lastPrompt = moment(localStorage.getItem("tokenFcmUpdated"));
        const lastToken = localStorage.getItem("tokenFcmUpdatedToken")
        const days = moment(today).diff(lastPrompt, "days");
        if(isNaN(days) || days > 14 || lastToken != token) {
            localStorage.setItem("tokenFcmUpdated", new Date())
            localStorage.setItem("tokenFcmUpdatedToken", token)
            const dataRef = collection(firestoreDb, `business/${user.uid}/data`)
            const docRef = doc(dataRef, "tokens")
            await setDoc(docRef, {
                fcmToken: arrayUnion(token)
            }, { merge: true })
            // await setDoc(doc(collection(firestoreDb, `business/${user.uid}/data`) , `tokens`), {
            //     fcmToken: token
            // }, { merge: true })
        }
    }

    static async disableDates(user, dateRangeObject) {
        const appoitmentsCollectionsRef = collection(firestoreDb, `business/${user.uid}/appoitments`)
        const docRef = doc(appoitmentsCollectionsRef, "blocked")
        await setDoc(docRef, {
            "array": arrayUnion(dateRangeObject)
        }, { merge: true })
        return true
    }

    static async releaseDates(userUid, dateRangeObject) {
        const appoitmentsCollectionsRef = collection(firestoreDb, `business/${userUid}/appoitments`)
        const docRef = doc(appoitmentsCollectionsRef, "blocked")
        await setDoc(docRef, {
            "array": arrayRemove(dateRangeObject)
        }, { merge: true })
        return true
    }

    static async deleteAppoitment(user, appoitment) {
        await deleteDoc(doc(firestoreDb, `business/${user.uid}/appoitments/${appoitment.date}/dailyAppointmentsArray/${appoitment.id}`))
        return true
    }

    static async deleteCustomerNote(user, customerId, noteId, imageName ,isDefault) {
        if(!isDefault) {
            await deleteObject(ref(firebaseStorage, `${user.uid}/customers/${customerId}/${imageName}`))
        }
        await deleteDoc(doc(firestoreDb, `business/${user.uid}/users/${customerId}/notes/${noteId}`))
        return true
    }

    static async createBusiness(user, business) {
        const docRef = doc(firestoreDb, 'business', user.uid)
        const r1 = await setDoc(docRef, {
            businessId: md5(user.uid).slice(0, 8), 
            name: business.businessName || "",
            email: firebaseAuth !== null &&  firebaseAuth.currentUser !== null && firebaseAuth.currentUser.email || "",
            address: business.address || "",
            logoUrl: business.logo || "",
            appointmentMargin: Number(business.appointmentMargin) || 0,
            category: business.category || "",
            hasAcceptTerms: !!business.hasAcceptTerms,
            showPricesToCustomers: !!business.showPricesToCustomers,
            colorScheme: business.colorScheme || { primaryColor: '#2F80ED' },
            backgroundUrl: business.backgroundUrl || "",
            facebookLink: business.facebookLink || "",
            instagramLink: business.instagramLink || "",
            phoneNumber: business.phoneNumber || '',
            openingHour: business.openingHour._i || "",
            closingHour: business.closingHour._i || "",
            daySpecialHours: business.daySpecialHours || {},
            availableWeekdays: business.days || [],
            services: business.services || [],
            employeNames: [user.displayName]
        }, { merge: true })

        return true
    }

    static async addAppoitment(businessId, appoitment) {
        const businessRef = collection(firestoreDb, 'business')
        const q = query(businessRef, where("businessId", "==", businessId), limit(1))
        const snapshot = await getDocs(q)
        if(!snapshot.empty) {
            
            const hiddenUserId = md5(appoitment.phoneNumber)
            const userRef = doc(snapshot.docs[0].ref, `users/${hiddenUserId}`)

            try {
                await setDoc(userRef, {
                    phoneNumber: appoitment.phoneNumber,
                    name: appoitment.name || ""
                })
            }
            catch(e) {
                console.error(e)
            }

            const appoitmentsCollectionsRef = collection(snapshot.docs[0].ref, "appoitments", appoitment.date, "dailyAppointmentsArray")
            addDoc(appoitmentsCollectionsRef, {
                price: appoitment.price || "",
                date: appoitment.date,
                businessId: businessId,
                duration: appoitment.duration,
                startTime: appoitment.startTime,
                service: appoitment.service,
                notes: appoitment.notes || "",
                user: userRef.id
            })

            return {ok:true}
        }
        else
            return null
    }

    static async addCustomerNote(user, userId, note) {
        const userCollections = collection(firestoreDb,`business/${user.uid}/users/${userId}/notes`)
        await addDoc(userCollections,note)
        return {ok:true}
    }

    static async createAppoitmentsSnapshotListener(businessId, date, callback) {
        const businessRef = collection(firestoreDb, 'business')
        const q = query(businessRef, where("businessId", "==", businessId), limit(1))
        const snapshot = await getDocs(q)
        if(!snapshot.empty) {
            const appoitmentsCollectionsRef = collection(snapshot.docs[0].ref, "appoitments", date, "dailyAppointmentsArray")
            return onSnapshot(query(appoitmentsCollectionsRef), (document) => {
                const source = document.metadata.hasPendingWrites ? "Local" : "Server";
                const appointments = []
                document.forEach(a => appointments.push({...a.data(), id: a.id}))
                callback({ data: appointments, source })
            })
        }
        else
            return null        
    }

    static createBusinessBlockedDaysSnapshotListener(uid, callback) {
        return onSnapshot(doc(firestoreDb, `business/${uid}/appoitments/blocked`), async (document) => {
            const source = document.metadata.hasPendingWrites ? "Local" : "Server";
            const data = document.data()

            return callback({ ok: true, data: data, source:source })
        })
    }

    static createBusinessCustomerNotesSnapshotListener(uid, userId, callback) {
        const notesCollectionsRef = collection(firestoreDb,`business/${uid}/users/${userId}/notes`)
        return onSnapshot(query(notesCollectionsRef), (document) => {
            const source = document.metadata.hasPendingWrites ? "Local" : "Server";
            const notes = []
            document.forEach(a => notes.push({...a.data(), id: a.id}))
            callback(notes)
        })
    }

    static createStatsSnapshotListener(user, callback) {
        // let data;
        // const statsCollectionsRef = collection(firestoreDb, `business/${user.uid}/stats`)

        // await getDocs(statsCollectionsRef).then((snap) => {
        //     const newData = snap.docs
        //     .map((doc) => ({...doc.data(), id:doc.id }));
        //     console.log(newData)
        //     data = newData[0]
        // })
        // return {ok:true ,data:data }
      
        // return callback
        // const statsCollectionsRef = collection()
        // console.log(collection(firestoreDb,`business/${user.uid}/stats`))
        
        return onSnapshot(doc(firestoreDb, `business/${user.uid}/data/stats`), async (document) => {
            const source = document.metadata.hasPendingWrites ? "Local" : "Server";
            const data = document.data()

            return callback({ ok: true, data:data, source:source })
        })

    }

    static async getWorkersAppointments(user, uids, date) {
        const queries = uids.map(uid => collection(firestoreDb, `business/${uid}/appoitments/${date}/dailyAppointmentsArray`))
        const appointmentsSnapshot = await Promise.all(queries.map(q => getDocs(q)))
        const appointments = []
        appointmentsSnapshot.map(a => a.forEach(k => appointments.push(k.data())))
        await Promise.all(appointments.map(async app => {
            let userDoc = null 
            try {
                userDoc = await getDocFromCache(doc(firestoreDb, `business/${user.uid}/users/${app.user}`))
                if(!userDoc.exists()) {
                    userDoc = await getDoc(doc(firestoreDb, `business/${user.uid}/users/${app.user}`))
                }
            }
            catch(e) {
                userDoc = await getDoc(doc(firestoreDb, `business/${user.uid}/users/${app.user}`))
            }
            app.userName = userDoc.data().name
            app.phoneNumber = userDoc.data().phoneNumber
        }))
        return appointments
    }

    static createAppoitmentsSnapshotListenerAuthorized(user, date, callback) {
        const appoitmentsCollectionsRef = collection(firestoreDb, `business/${user.uid}/appoitments/${date}/dailyAppointmentsArray`)
        return onSnapshot(query(appoitmentsCollectionsRef), async (document) => {
            const source = document.metadata.hasPendingWrites ? "Local" : "Server";
            
            const appointments = []
            document.forEach(a => appointments.push({...a.data(), id: a.id}))

            if(appointments.length > 0) {
                const userIds = Array.from(new Set(appointments.map(app => app.user)))
                const users = {}

                while(userIds.length) {
                    const batch = userIds.splice(0, 10)
                    const q = query(collection(firestoreDb, `business/${user.uid}/users`), 
                        where(documentId(), "in", batch ))
    
                    let usersSnapshot = await getDocsFromCache(q)
                    console.log(`got from cache ${!usersSnapshot.empty}`)

                    if(usersSnapshot.empty) {
                        usersSnapshot = await getDocs(q)
                    }
                    
                    usersSnapshot.forEach(user => {
                        users[user.id] = user.data()
                    })
                }
                
                appointments.map(app => {
                    app.userName = users[app.user].name
                    app.phoneNumber = users[app.user].phoneNumber
                })
            }

            callback({ data: appointments, source })
        })      
    }

    static async uploadImageToBusiness(user, imageFileName, imageFile) {
        const imagesRef = ref(firebaseStorage, `${user.uid}/publicImages/${imageFileName}`);
        const r = await uploadBytes(imagesRef, imageFile)
        return await getDownloadURL(r.ref)
    }

    static async uploadImageToCustomer(user, id, imageFileName, imageFile) {
        const imagesRef = ref(firebaseStorage, `${user.uid}/customers/${id}/${imageFileName}`);
        const r = await uploadBytes(imagesRef, imageFile)
        return await getDownloadURL(r.ref)
    }

    static async getImageDownloadURL(path) {
        const imagesRef = ref(firebaseStorage, path);
        return await getDownloadURL(imagesRef)
    }
}



