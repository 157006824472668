import React from 'react'
import { Alert, Snackbar } from '@mui/material';
import EventBus from '../../services/eventbus/EventBus';

const defaultState = {text: '', open: false, duration: null, status: 'info'}


export const Alerts = () => {
    const [state, setState] = React.useState(defaultState);

    const handleClose = () => {
        setState(defaultState)
    }

    React.useEffect(() => {
        const subscription1 = EventBus.subscribe("SHOW_ALERT", (data) => {
            setState({ open: true, text: data.text, duration: 2500, status: data.status || 'success' })
        })

        const subscription3 = EventBus.subscribe("SPECIAL_SHOW_ALERT", (data) => {
            setState({ open: true, text: data.text, duration: null, status: data.status || 'success' })
        })

        const subscription2 = EventBus.subscribe("HIDE_ALERT", handleClose)

        return function cleanup () {
            subscription1.unsubscribe()
            subscription2.unsubscribe()
            subscription3.unsubscribe()
        }

    }, [])

    return (<>
    <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} open={state.open} autoHideDuration={state.duration} onClose={handleClose}>
        <Alert variant='filled' severity={state.status} sx={{ width: '100%' }}>
            <div className='tracking-wide mr-1 ml-1'>{state.text}</div>
        </Alert>
    </Snackbar>
      </>
    )
}