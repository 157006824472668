import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export const LoginProtectedRoute = ({children, ...rest}) => {
    const isLoggedIn = !!localStorage.getItem("sessionContext") ? 
        !!JSON.parse(localStorage.getItem("sessionContext")).user.uid : false

    return (
        <Route exact {...rest}
          render={({ location }) => isLoggedIn ? (children) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location }
                }}
              />
            )
          }
        />)
}